import {createSlice} from '@reduxjs/toolkit'


const appSlice = createSlice({
    name: 'app',
    initialState: {
        loading: true,
        popups: [],
        settings: {},
        cards: [],
        banner: [],
        currentCategory: 3
    },
    reducers: {
        appLoaded(state) {
            state.loading = false;
        },
        setAppData(state, {payload}) {
            state.popups = payload.popups;
            const category = payload.categories;
            if (category) {
                category.forEach((item) => {
                    if (item.ParamCode.indexOf('tieude') > -1 || item.ParamCode.indexOf('chuchay') > -1) {
                        state.settings[item.ParamCode] = item.Desctiption;
                    }
                    if (item.ParamCode === 'bankID' || item.ParamCode === 'bankCode' || item.ParamCode === 'accountName') {
                        state.settings[item.ParamCode] = item.ParamValue;
                    }
                    if (item.ParamCode.indexOf('dong') > -1) {
                        if (!state.settings.column3) {
                            state.settings.column3 = [];
                        }
                        state.settings.column3.push(item);
                    }
                    if (item.ParamCode.indexOf('fc') > -1) {
                        if (!state.settings.column2) {
                            state.settings.column2 = [];
                        }
                        state.settings.column2.push(item);
                    }
                    if (item.ParamCode.indexOf('abc') > -1) {
                        if (!state.settings.column1) {
                            state.settings.column1 = [];
                        }
                        state.settings.column1.push(item);
                    }
                    if (item.ParamCode === 'GiaVanMay') {
                        state.settings.spinCost = parseInt(item.ParamValue);
                    }
                    if (item.ParamCode.indexOf('filter') > -1) {
                        if (!state.settings.filterAccount) {
                            state.settings.filterAccount = [];
                        }
                        state.settings.filterAccount.push(item);
                    }
                    if (item.ParamCode === 'banner_video') {
                        state.settings.banner_video = item.ParamValue;
                    }
                    if (item.ParamCode.indexOf('bannerright') > -1 || item.ParamCode.indexOf('leftbanner') > -1) {
                        state.settings[item.ParamCode] = item.ParamValue;
                    }
                    if (item.ParamCode.indexOf('slideAccBp') > -1 || item.ParamCode.indexOf('slideAccMothe') > -1 || item.ParamCode.indexOf('slideAccDH') > -1) {
                        if (item.ParamValue) {
                            state.banner.push(item);
                        }
                    }
                });
            }

            state.gRecaptchaSiteKey = payload.gRecaptchaSiteKey;
        },
        onMountHomePage(state) {
            state.isHomePage = true;
        },
        onUnMountHomePage(state) {
            state.isHomePage = false;
        },
        setAppCardData(state, {payload}) {
            const data = payload.reduce((initial, item) => {
                const hasProvider = initial.some(i => i.providerCode === item.provider_code);
                if (!hasProvider) {
                    initial = [...initial, {providerCode: item.provider_code, priceList: [item.card_amount]}]
                } else {
                    initial = initial.map((iItem) => {
                        if (iItem.providerCode === item.provider_code) {
                            iItem.priceList = [...iItem.priceList, item.card_amount];
                        }
                        return iItem;
                    })
                }
                return initial;
            }, [])

            data.forEach((item) => {
                item.priceList = item.priceList.sort((a, b) => a - b);
            })
            state.cards = data;
            console.log(data);
        },


        setCurrentCategory(state, {payload}) {
            state.currentCategory = payload.cate;
        }

    }

})

export const {
    appLoaded,
    setAppData,
    onMountHomePage,
    onUnMountHomePage,
    setAppCardData,
    setCurrentCategory
} = appSlice.actions

export default appSlice.reducer
