import group42 from "../../assets/image/Group_42.png";
import Footer from "../../layout/Footer/Footer";
import './Auction.scss';
import {useGetAuctionHistoryQuery, useGetAuctionListQuery} from "../../redux/services/common";
import AuctionItem from "./AuctionItem";
import {useEffect, useState} from "react";
import AutoRefetchTimer from "./AutoRefetchTimer";
import {getTopupStatus, numberWithCommas, toDate} from "../../utils/utils";
import moment from "moment";

const Auction = () => {
    const {data, error, isLoading, refetch} = useGetAuctionListQuery();
    const historyData = useGetAuctionHistoryQuery();

    const onRefetch = () => {
        refetch();
    }
    return (
        <div className="row flex-grow-1">
            <div className="col-12 layout-padding auction-page">
                <div className="row page-header align-items-center">
                    <div className="col-6 page-title">
                        <h4 className="m-0">Đấu giá</h4>
                    </div>
                    <div className="col-6 d-flex justify-content-end">
                        <AutoRefetchTimer onAction={onRefetch}/>
                    </div>
                </div>
                <div className="row page-content auction-items">
                    {isLoading ?
                        <div className="col-12 my-2">
                            Loading
                        </div> :
                        data && data.length > 0 ? data.map(item =>
                                <div className="col-12 col-md-6 col-lg-4 col-xl-4 col-xxl-4 my-2" key={item.id}>
                                    <AuctionItem auctionData={item}/>
                                </div>
                            ) :
                            <div className="col-12 my-2">
                                Hiện không có cuộc đấu giá nào đang diễn ra
                            </div>
                    }
                    <div className="col-12 mt-3">
                        <h4 className="mb-3">Lịch sử đấu giá</h4>
                        <div className="row">
                            <div className="col-12">
                                <table className="table table-striped table-hover table-bordered tbl-history">
                                    <thead>
                                    <tr>
                                        <th>Ngày giờ</th>
                                        <th>Người thắng</th>
                                        <th>Thông tin acc</th>
                                        <th>Giá</th>
                                    </tr>
                                    </thead>

                                    <tbody>
                                    {historyData.isLoading ?
                                        <tr>
                                            <td colSpan={100}>Loading...</td>
                                        </tr> :
                                        historyData.data && historyData.data.length > 0 ? historyData.data.map(item => <tr key={item.id}>
                                                <td>{toDate(item.won_at)}</td>
                                                <td>{item.winner}</td>
                                                <td>{item.loai_acc && (item.loai_acc === 2 || item.loai_acc === 3) ? `${item.Description} + ${item.the_mau} Thẻ màu + ${item.ngay_svip} ngày svip + ${item.point} point + ${item.the_bv} Thẻ BV + ${item.the_ncl} Thẻ NCL + ${item.the_30_percent} Thẻ 30%` : item.Description }</td>
                                                <td>{numberWithCommas(item.win_price)}</td>
                                            </tr>)
                                            :
                                            <tr>
                                                <td colSpan={100}>Không có dữ liệu</td>
                                            </tr>
                                    }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
}

export default Auction;