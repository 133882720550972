import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {numberWithCommas} from "../../utils/utils";
import moment from "moment";
import {OverlayTrigger, Popover} from "react-bootstrap";
import eye from "../../assets/image/eye.svg";
import {useGetTransactionHistoryAllQuery} from "../../redux/services/common";

const TransactionHistoryAll = props => {
    const {data, error, isLoading} = useGetTransactionHistoryAllQuery();

    return (
        <>
            <div className="row page-header mb-2">
                <div className="col-12 page-title" style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }}>
                    <h4>Lịch sử mua</h4>
                </div>
            </div>
            <div className="row page-content">
                <div className="col-12">
                    <table className="table table-striped table-hover table-bordered tbl-history">
                        <tbody>
                        {isLoading ?
                            <tr>
                                <td colSpan={100}>Loading...</td>
                            </tr> :
                            data?.data?.length > 0 ? data.data.map(item => <tr key={item.ID}>
                                    <td>{item.AccCode}</td>
                                    <td>{`${item.UserName} mua ${item.Title}`}</td>
                                    <td>{`#${item.ID}`}</td>
                                    <td>{moment(item.ExchangeDate).subtract(7, 'hours').format("HH:mm:ss, Do MMMM, YYYY")}</td>
                                    <td>{numberWithCommas(item.price ?? 0)} FGC</td>
                                </tr>)
                                :
                                <tr>
                                    <td colSpan={100}>Không có dữ liệu</td>
                                </tr>
                        }
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    );
};

TransactionHistoryAll.propTypes = {};

export default TransactionHistoryAll;