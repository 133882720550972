import {Outlet} from "react-router-dom";
import Header from "./Header/Header";
import ZaloSupport from "../components/ZaloSupportComponent/ZaloSupport";
import Footer from "./Footer/Footer";
import RightBanner from "./RightBanner";
import LeftBanner from "./LeftBanner";

const Layout = () => {

    return (
        <div className="wrapper position-relative">
            <LeftBanner/>
            <section className="container-xl bg-white d-flex flex-column" style={{minHeight: '100%', position: 'relative', zIndex: '200'}}>
                <Header/>
                <Outlet/>
                <ZaloSupport/>
                <Footer/>
            </section>
            <RightBanner/>

        </div>
    );

}

export default Layout;