import {api} from "./api";

const tag = "Account";
const uri = "account";

export const accountApi = api.injectEndpoints({
    endpoints: (build) => ({
        addAccount: build.mutation({
            query: (body) => ({
                url: `${uri}/signup`,
                method: 'POST',
                body,
            }),
            invalidatesTags: [{type: tag, id: 'LIST'}],
        }),
        updateAccount: build.mutation({
            query: (body) => ({
                url: `${uri}/${body.id}`,
                method: 'PUT',
                body,
            }),
            invalidatesTags: (item) => [{type: tag, id: item?.id}],
        }),
        changePw: build.mutation({
            query: (data) => {
                const {id, ...body} = data;
                return {
                    url: `${uri}/change-pw/${id}`,
                    method: 'POST',
                    body,
                };
            }
        }),
      
    })

})

export const {
    useAddAccountMutation,
    useUpdateAccountMutation,
    useChangePwMutation,
} = accountApi