import './AccountDetail.css';
import Footer from "../../layout/Footer/Footer";
import {useGetAccountDetailQuery} from "../../redux/services/common";
import {Link, useNavigate, useParams} from "react-router-dom";
import {isUrl, numberWithCommas} from "../../utils/utils";
import PurchaseAccountButton from "../../components/PurchaseAccount/PurchaseAccountButton";
import Breadcrumb from "../../components/BreadcumbComponent/Breadcrumb";
import {SALE_PRICE_CARD, STATIC_FILE_SERVER} from "../../config/app.config";
import anh_trang_chu from "../../assets/image/anh-trang-chu.png";
import {useEffect, useRef, useState} from "react";
import PhotoSwipeLightbox from 'photoswipe/lightbox';
import PhotoSwipe from 'photoswipe';
import 'photoswipe/style.css';
import {openPhoto} from "../../utils/photo-swipe";


const AccountDetail = () => {
    const params = useParams();
    const {data, error, isLoading} = useGetAccountDetailQuery(params.id);
    const navigate = useNavigate();
    const [imagesGallery, setImagesGallery] = useState([]);
    const countHasChangedRef = useRef(false);


    const onPurchasedAccount = () => {
        navigate(`/`);
    }

    const handleClickImage = (img) => {
        if (!img) {
            return;
        }
        openPhoto(isUrl(img) ? img : `${STATIC_FILE_SERVER}${img}`);
    }

    useEffect(() => {
        setImagesGallery([data?.Avatar, data?.Avatar1, data?.Avatar2]);
    }, [data]);

    return (
        <section className={'section--body bg__image pt-0'}>
            <div className="container-fluid">
                <Breadcrumb title={data?.Title}/>
                <div className="content">
                    <div className="row text-center">
                        <h1 className="col-md-12 text-white">Thông tin chi tiết ACC</h1>
                    </div>
                    {
                        isLoading ? <div>LOADING...</div> : data ? <div className="row">
                                <div className="col-md-3">
                                    {/*<object data={isUrl(data.Avatar) ? data.Avatar : `${STATIC_FILE_SERVER}${data.Avatar}`} type="image/png" className={'product-image'}>*/}
                                    {/*    <img src={anh_trang_chu} alt="default product image" className={'product-image'}/>*/}
                                    {/*</object>*/}
                                    {/*{data.Avatar1 &&*/}
                                    {/*<object data={isUrl(data.Avatar1) ? data.Avatar1 : `${STATIC_FILE_SERVER}${data.Avatar1}`} type="image/png" className={'product-image'}>*/}
                                    {/*    <img src={anh_trang_chu} alt="default product image" className={'product-image'}/>*/}
                                    {/*</object>*/}
                                    {/*}*/}
                                    {/*{data.Avatar2 &&*/}
                                    {/*<object data={isUrl(data.Avatar2) ? data.Avatar2 : `${STATIC_FILE_SERVER}${data.Avatar2}`} type="image/png" className={'product-image'}>*/}
                                    {/*    <img src={anh_trang_chu} alt="default product image" className={'product-image'}/>*/}
                                    {/*</object>*/}
                                    {/*}*/}

                                    <div className="pswp-gallery" id={`account_gallery${params.id}`}>
                                        {imagesGallery.map((image, index) => (
                                            image &&
                                            // <a
                                            //     href={isUrl(image) ? image : `${STATIC_FILE_SERVER}${image}`}
                                            //     data-pswp-width={1600}
                                            //     data-pswp-height={900}
                                            //     key={`${params.id}` + '-' + index}
                                            //     target="_blank"
                                            //     rel="noreferrer"
                                            // >
                                            <img src={isUrl(image) ? image : `${STATIC_FILE_SERVER}${image}`} alt="" className={'product-image cursor-pointer'} onClick={() => handleClickImage(image)} key={`${params.id}` + '-' + index}/>
                                            // </a>
                                        ))}
                                    </div>

                                    {/*<img className={'product-image'} src={`https://accfifa.vn/${data.Avatar}`}/>*/}
                                </div>
                                <div className="col-md-9">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <span className="text-white" style={{width: '70px'}}>Mã Acc: </span>
                                            <span className="text-white"><span className="badge badge-info">{data.AccCode}</span></span>
                                        </div>
                                    </div>
                                    <div className="row mb-2">
                                        <div className="col-md-12">
                                            <span className="text-white" style={{width: '70px'}}>Tên Acc: </span>
                                            <span className="text-white"><b>{data.Title}</b></span>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <span className="text-white" style={{width: '70px'}}>Mô tả: </span>
                                            <div className="text-white" dangerouslySetInnerHTML={{__html: data.AccInfo}}/>
                                        </div>
                                    </div>
                                    <div className="row flex-column">
                                        <div className="col-md-12">
                                            {data.use_card_price ? <h2 style={{color: '#FCC83B'}} className={'mb-2 d-flex align-items-center'}>
                                                <span className={'mr-2'}>Card: </span>
                                                <span className="d-flex flex-column">
                                                            <span style={{'color': data.sale_price && data.sale_price > 0 ? 'gray' : 'inherit', 'textDecoration': data.sale_price && data.sale_price > 0 ? 'line-through' : 'none'}}>{numberWithCommas(data.CardPrice || 0)} FGC</span>
                                                    {data.sale_price && data.sale_price > 0 ?
                                                        <span>{numberWithCommas(data.sale_price + (data.sale_price * SALE_PRICE_CARD / 100) || 0)} FGC</span> : null
                                                    }
                                                        </span>
                                                {/*Card: {numberWithCommas(data.CardPrice || 0)} VND */}
                                            </h2> : null}
                                            <h2 style={{color: '#FCC83B'}} className={'mb-2 d-flex align-items-center'}>
                                                <span className={'mr-2'}>ATM: </span>
                                                <span className="d-flex flex-column">
                                                            <span style={{'color': data.sale_price && data.sale_price > 0 ? 'gray' : 'inherit', 'textDecoration': data.sale_price && data.sale_price > 0 ? 'line-through' : 'none'}}>{numberWithCommas(data.ATMPrice || 0)} FGC</span>
                                                    {data.sale_price && data.sale_price > 0 ?
                                                        <span>{numberWithCommas(data.sale_price || 0)} FGC</span> : null
                                                    }
                                                        </span>
                                                {/*ATM: {numberWithCommas(data.ATMPrice || 0)} VND */}
                                            </h2>
                                        </div>
                                    </div>
                                    <div className={'row'}>
                                        <div className="col-md-12">
                                            <PurchaseAccountButton account={data} onPurchased={onPurchasedAccount}/>
                                        </div>
                                    </div>
                                </div>
                            </div> :
                            <div className="row text-center">
                                <h4 className="col-md-12 text-white">Account không tồn tại</h4>
                            </div>
                    }

                </div>
            </div>
            <div className={'container-fluid'}>
                <div className="border__top"></div>
            </div>
            <Footer/>
        </section>
    );
}
export default AccountDetail;