import './SiteStat.scss';
import tag from '../../../assets/image/tag.png';
import groups from '../../../assets/image/groups.png';
import report from '../../../assets/image/report.png';
import {useGetSiteStatQuery} from "../../../redux/services/common";

const SiteStat = () => {

    const {data, error, isLoading} = useGetSiteStatQuery();

    return (
        <div className="row site-stat">
            <div className="col-12 layout-padding">
                <div className="row h-100 align-items-center">
                    <div className="col-4">
                        <div className="row stat">
                            <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4 d-flex justify-content-center mb-2">
                                <div className="stat-icon">
                                    <img src={groups} alt="member"/>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-8 col-lg-8 col-xl-8">
                                <div className="stat-number">{data?.totalMember ?? 0}</div>
                                <div className="stat-label">Thành viên</div>
                            </div>
                        </div>

                    </div>
                    <div className="col-4">
                        <div className="row stat">
                            <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4 d-flex justify-content-center mb-2">
                                <div className="stat-icon">
                                    <img src={tag} alt="groups"/>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-8 col-lg-8 col-xl-8">
                                <div className="stat-number">{+(data?.totalSold ?? 0)*2}</div>
                                <div className="stat-label">Acc đã bán</div>
                            </div>
                        </div>

                    </div>
                    <div className="col-4">
                        <div className="row stat">
                            <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4 d-flex justify-content-center mb-2">
                                <div className="stat-icon">
                                    <img src={report} alt="report"/>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-8 col-lg-8 col-xl-8">
                                <div className="stat-number">{data?.totalSelling ?? 0}</div>
                                <div className="stat-label">Đang bán</div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
}
export default SiteStat;