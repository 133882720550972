import ReactPaginate from 'react-paginate';

const Pagination = ({pageCount, onChangePage}) => {

    const handlePageClick = (event) => {
        onChangePage(event.selected + 1);
    };


    return (
        <ReactPaginate
            nextLabel="›"
            onPageChange={handlePageClick}
            pageRangeDisplayed={3}
            marginPagesDisplayed={2}
            pageCount={pageCount}
            previousLabel="‹"
            pageClassName="pagination-page"
            pageLinkClassName=""
            previousClassName="pagination-prev"
            previousLinkClassName=""
            nextClassName="pagination-next"
            nextLinkClassName=""
            breakLabel="..."
            breakClassName="pagination-page"
            breakLinkClassName=""
            containerClassName="pagination pagination-sm m-0 float-right"
            activeClassName="active"
            disabledClassName="disabled"
            renderOnZeroPageCount={null}
        />
    );
}
export default Pagination;