import {api} from "./api";
import {store} from "../store";


const tag = "Common";
const uri = "";

export const commonApi = api.injectEndpoints({
    endpoints: (build) => ({

        purchaseAccount: build.mutation({
            query: ({id, body}) => ({
                url: `${uri}/purchase-account/${id}`,
                method: 'POST',
                body
            }),
            invalidatesTags: (item) => [{type: 'Accounts', id: item?.id}],
        }),
        getListAccount: build.query({
            query: (params) => ({
                url: `${uri}/list-accounts`,
                method: 'GET',
                params: params
            }),
            providesTags: (result) => {
                return result && result.data ? [...result.data.map(({id}) => ({type: 'Accounts', id})), {type: 'Accounts', id: 'LIST'}] : ['Accounts'];
            },
        }),
        getAccountDetail: build.query({
            query: (id) => ({
                method: 'GET',
                url: `${uri}/account-details/${id}`
            }),
            providesTags: (post, err, id) => [{type: 'Accounts', id}],
        }),
        getTopupHistory: build.query({
            query: () => ({
                method: 'GET',
                url: `${uri}/topup-history`
            }),
            providesTags: (result) => {
                return result && result.data ? [...result.data.map(({id}) => ({type: 'Topup', id})), {type: 'Topup', id: 'LIST'}] : ['Topup'];
            },
        }),
        getTransactionHistory: build.query({
            query: () => ({
                method: 'GET',
                url: `${uri}/transaction-history`,
            }),
            providesTags: (result) => {
                return result && result.data ? [...result.data.map(({id}) => ({type: 'Transactions', id})), {type: 'Transactions', id: 'LIST'}] : ['Transactions'];
            },
        }),
        getTransactionHistoryAll: build.query({
            query: () => ({
                method: 'GET',
                url: `${uri}/transaction-history-all`,
            }),
            providesTags: (result) => {
                return result && result.data ? [...result.data.map(({id}) => ({type: 'TransactionsAll', id})), {type: 'TransactionsAll', id: 'LIST'}] : ['TransactionsAll'];
            },
        }),
        getPage: build.query({
            query: (params) => ({
                method: 'GET',
                url: `${uri}/page`,
                params: params
            }),
            providesTags: (post, err, code) => [{type: 'Page', code}],
        }),
        spinWheel: build.mutation({
            query: (body) => ({
                url: `${uri}/spin-wheel`,
                method: 'POST',
                body
            })
        }),
        topupCard: build.mutation({
            query: (body) => ({
                url: `${uri}/topup/web-topup`,
                method: 'POST',
                body
            })
        }),
        getTopCharger: build.query({
            query: (params) => ({
                method: 'GET',
                url: `${uri}/top-charger`,
                params
            }),
            providesTags: (post, err, code) => [{type: 'TopCharger', code}],
        }),
        getAuctionList: build.query({
            query: (params) => ({
                method: 'GET',
                url: `${uri}/auction/listing`
            }),
            transformResponse: response => {
                const dataReturn = response.map(auction => {
                    const user = (store.getState()).auth.user;
                    const bidders = (auction.bidders || []).sort((a, b) => a.bid_amount > b.bid_amount)
                    const highestBid = bidders && bidders.length > 0 ? bidders[0] : null;
                    const mybid = user ? bidders.find(bid => bid.user_id == user.ID) : null;
                    let currentPrice = highestBid ? highestBid.bid_amount : auction.start_price;
                    return {...auction, current_price: currentPrice, user_price: mybid ? mybid.bid_amount : 0, myBid: mybid}
                })
                console.debug(dataReturn);
                return dataReturn;
            },
            providesTags: (post, err, code) => [{type: 'Auction', code}],
        }),
        getAuctionHistory: build.query({
            query: (params) => ({
                method: 'GET',
                url: `${uri}/auction/history`
            }),
        }),
        placeBid: build.mutation({
            query: (body) => ({
                url: `${uri}/auction/place-bid/${body.auction_id}`,
                method: 'POST',
                body
            }),
            invalidatesTags: (item) => [{type: 'Auction', id: item?.id}],
        }),
        cancelBid: build.mutation({
            query: (body) => ({
                url: `${uri}/auction/cancel-bid/${body.id}`,
                method: 'POST',
                body
            }),
            invalidatesTags: (item) => [{type: 'Auction', id: item?.id}],
        }),
        getChargerStat: build.query({
            query: (params) => ({
                method: 'GET',
                url: `${uri}/charger-info/${params.id}`,
                params: params.searchParams
            })
        }),
        getSiteStat: build.query({
            query: (params) => ({
                method: 'GET',
                url: `${uri}/site-stat`,
            })
        }),

    })
})

export const {
    useGetListAccountQuery,
    useGetAccountDetailQuery,
    useGetTopupHistoryQuery,
    useGetTransactionHistoryQuery,
    useGetTransactionHistoryAllQuery,
    useGetTopChargerQuery,
    useGetAuctionListQuery,
    useGetAuctionHistoryQuery,
    useGetPageQuery,
    usePurchaseAccountMutation,
    useSpinWheelMutation,
    useTopupCardMutation,
    usePlaceBidMutation,
    useCancelBidMutation,
    useGetChargerStatQuery,
    useGetSiteStatQuery,

} = commonApi