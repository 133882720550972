import {STATIC_FILE_SERVER} from "../config/app.config";
import {useSelector} from "react-redux";

const LeftBanner = () => {

    const leftbanner = useSelector(state => state.app.settings.leftbanner);

    return (leftbanner ?
            <div className="left-banner d-none d-xxl-flex">
                <img className="w-100 h-100" src={`${STATIC_FILE_SERVER}${leftbanner}`} alt="left-banner"/>
            </div>
            : null
    );
}
export default LeftBanner;