import AccList from "../../components/AccListComponent/AccList";
import Banner from "./Banner/Banner";
import AuctionWidget from "../../components/AuctionWidgetComponent/AuctionWidget";
import SiteStat from "./SiteStat/SiteStat";
import {useEffect} from "react";
import {useSelector} from "react-redux";
import {useModal} from "react-modal-hook";
import PopupModal from "../../components/ModalComponent/PopupModal";

const Home = () => {
    const popup = useSelector(state => state.app.popups.find(item => item.PageCode === 'ThongBaoHome'));

    const [showPopup, hidePopup] = useModal(() => (
        <PopupModal openModal={true} onClose={hidePopup} content={popup.Content}/>
    ), [popup.Content]);

    useEffect(() => {
        showPopup();
    }, [popup.Content]);
    return (
        <>
            <div className="row">
                <div className="col-12 layout-padding">
                    <div className="row mt-3">
                        <div className="col-12">
                            <Banner/>
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-12">
                            <AuctionWidget/>
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-12">
                            <AccList/>
                        </div>
                    </div>
                </div>
            </div>
            <SiteStat/>

        </>
    );
}
export default Home;