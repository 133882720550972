import {Navigate, Outlet, useLocation} from 'react-router-dom'
import {useAuth} from "../utils/hooks/useAuth";

const ProtectedView = () => {
    const auth = useAuth();
    const location = useLocation();

    return auth.user ? (
        <Outlet/>
    ) : (
        <Navigate to="/" state={{from: location}}/>
    )
}

export default ProtectedView;
