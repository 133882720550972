import {Modal} from "react-bootstrap";
import popup_bg from '../../assets/image/popup.png';
import {useEffect, useState} from "react";

const PopupModal = ({content, openModal, onClose}) => {
    const [show, setShow] = useState(false);
    const handleClose = () => {
        setShow(false);
        onClose(false);
    };

    const handleShow = () => setShow(true);

    useEffect(() => {
        setShow(openModal);
    }, [openModal])

    return (
        <Modal
            show={show}
            size="lg"
            onHide={() => setShow(false)}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
        >
            <Modal.Body className={'p-0'}>
                {/*<div className={'row position-absolute d-none d-md-block'} >*/}
                {/*    <div className={'col-md-12'}>*/}
                {/*        <img className={'w-100'} src={popup_bg}/>*/}
                {/*    </div>*/}
                {/*</div>*/}
                <div className="row popup-content" style={{overflowY: 'auto', maxHeight: '500px'}}>
                    <div className="col-md-12" style={{height: '100%', paddingTop: '30px', background: '#ffffff'}}>
                        <div dangerouslySetInnerHTML={{__html: content}}></div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
}
export default PopupModal;