import vong_quay from '../assets/image/vong_quay.png';
import tick from '../assets/sound/tick.mp3';
import Swal from "sweetalert2";
import TweenMax from 'greensock';

window.TweenMax = TweenMax;

let theWheel = null;
const duration = 10; //Thời gian kết thúc vòng quay
const spins = 8; //Quay nhanh hay chậm 3, 8, 15
let wheelSpinning = false;

//Tạo âm thanh và tải tập tin tick.mp3.

const audio = new Audio(tick);

function playSound() {
    audio.pause();
    audio.currentTime = 0;
    audio.play();
}


export const resetWheel = () => {
    wheelSpinning = false;
    theWheel.stopAnimation(false);  // Stop the animation, false as param so does not call callback function.
    theWheel.rotationAngle = 0;     // Re-set the wheel angle to 0 degrees.
    theWheel.draw();                // Call draw to render changes to the wheel.
}


export const drawWheel = (handleStop) => {
   

    theWheel = new window.Winwheel({
        'canvasId': 'myCanvas',
        'drawMode': 'image',
        'numSegments': 6,     // Chia 6 phần bằng nhau
        'imageOverlay': false,
        'segments':        // Các thành phần bao gồm màu sắc và văn bản.
            [
                {'text': 'Acc BP trắng từ 1B - 10B'},
                {'text': 'Acc trên 100 thẻ và bp trắng'},
                {'text': 'Acc có đội hình CLB từ +1 -> +5'},
                {'text': 'Acc có cầu thủ +7, +8'},
                {'text': 'Acc có cầu thủ ICON, TOTY 21'},
                {'text': 'Acc có BP và FC'}
            ],
        'animation': {
            'type': 'spinToStop',
            'duration': duration,
            'spins': spins,
            'callbackSound': playSound,     //Hàm gọi âm thanh khi quay
            'soundTrigger': 'pin',         //Chỉ định chân là để kích hoạt âm thanh
            'callbackFinished': handleStop,    //Hàm hiển thị kết quả trúng giải thưởng
        },
        'pins':
            {
                'number': 24   //Số lượng chân. Chia đều xung quanh vòng quay.
            }
    });


    const secondImg = new Image();
    secondImg.onload = function () {
        theWheel.wheelImage = secondImg;    // Make wheelImage equal the loaded image object.
        theWheel.draw();
        // Also call draw function to render the wheel.
    }
    secondImg.src = vong_quay;
};
export const startWheel = (prize) => {
    if (!wheelSpinning) {
        wheelSpinning = true;
        let stopAt = theWheel.getRandomForSegment(prize);
        theWheel.animation.stopAngle = stopAt;
        theWheel.startAnimation();
    }

}