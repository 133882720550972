import {useState, useEffect} from "react";

const AutoRefetchTimer = (props) => {
    const [updateSec, setUpdateSec] = useState(60);

    const onReset = () => {
        props.onAction();
        setUpdateSec(60);
    }

    useEffect(() => {
        const intervalRefetch = setInterval(() => {
            if (updateSec === 0) {
                console.debug('update listing');
                props.onAction();
                setUpdateSec(60);
            } else {
                setUpdateSec(updateSec - 1);
            }
        }, 1000)
        return () => {
            clearInterval(intervalRefetch);
        }
    }, [props, updateSec]);

    return (
        <div className="d-flex align-items-center" style={{fontSize: '13px'}}>
            Cập nhật dữ liệu mới sau {updateSec} giây <button className="btn btn-link" style={{fontSize: '13px'}} onClick={onReset}>Cập nhật ngay</button>
        </div>
    );

}
export default AutoRefetchTimer;