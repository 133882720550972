import anh_trang_chu from '../../assets/image/anh-trang-chu.png';
import {Link} from "react-router-dom";
import {useGetListAccountQuery} from "../../redux/services/common";
import {getContentInHTML, isUrl, numberWithCommas} from "../../utils/utils";
import PurchaseAccountButton from "../PurchaseAccount/PurchaseAccountButton";
import {SALE_PRICE_CARD, STATIC_FILE_SERVER} from "../../config/app.config";
import default_acc_ava from '../../assets/image/default-acc-ava.png';
import {useEffect, useState} from "react";
import './AccList.scss';
import searchIcon from '../../assets/image/Search.svg';
import Pagination from "../Pagination/Pagination";
import {useDispatch, useSelector} from "react-redux";
import {setCurrentCategory} from "../../redux/slices/appSlice";
import {faPlay} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const AccList = () => {

    const [searchParams, setSearchParams] = useState({
        priceRange: "",
        page: 1,
        pageSize: 12,
        loai_acc: 4
    });
    const [playingAccs, setPlayingAccs] = useState({});
    const listFilter = useSelector(state => state.app.settings.filterAccount)
    const [pageCount, setPageCount] = useState(0);
    const [priceRange, setPriceRange] = useState("");
    const {data, error, isLoading} = useGetListAccountQuery(searchParams, {refetchOnMountOrArgChange: true});

    const dispatch = useDispatch();

    const handleChangePage = (page) => {
        onSearch({page});
    }

    const onSearch = (values) => {
        const paramsSearch = {...searchParams, ...values};
        setSearchParams(paramsSearch);
    }
    const onChangeCategory = (cate) => {
        dispatch(setCurrentCategory({cate: cate}));
        setSearchParams({...searchParams, loai_acc: cate});
    }

    const onChangeFilter = (e) => {
        setPriceRange(e.target.value);
        onSearch({priceRange: e.target.value});
    }
    const handlePurchaseAccount = () => {

    }
    const onClickPlayVideo = (acc) => {
        if (acc.youtube_link) {
            setPlayingAccs({...playingAccs, [acc.ID]: true})
        }
    }

    useEffect(() => {
        // Fetch items from another resources.
        setPageCount(Math.ceil((data?.count ?? 1) / searchParams.pageSize));
    }, [data, searchParams]);


    return (
        <div className="account-list-container mb-2">
            <div className="row px-2">
                <div className="col-sm-12 col-md-8 col-lg-8 col-xl-8 account-category mb-2 px-0">
                    <ul className="ac-nav w-100">
                        <li className="nav-item">
                            <a className={`nav-cate-link ${searchParams.loai_acc === 4 ? 'active' : ''}`} onClick={() => onChangeCategory(4)}>TẤT CẢ</a>
                        </li>
                        <li className="nav-item">
                            <a className={`nav-cate-link ${searchParams.loai_acc === 3 ? 'active' : ''}`} onClick={() => onChangeCategory(3)}>ACC MỞ THẺ</a>
                        </li>
                        {/*<li className="nav-item">*/}
                        {/*    <a className={`nav-cate-link ${searchParams.loai_acc === 1 ? 'active' : ''}`} onClick={() => onChangeCategory(1)}>ACC ĐỘI HÌNH</a>*/}
                        {/*</li>*/}
                        <li className="nav-item">
                            <a className={`nav-cate-link ${searchParams.loai_acc === 5 ? 'active' : ''}`} onClick={() => onChangeCategory(5)}>ACC THẺ CÓ FC</a>
                        </li>
                        <li className="nav-item">
                            <a className={`nav-cate-link ${searchParams.loai_acc === 2 ? 'active' : ''}`} onClick={() => onChangeCategory(2)}>ACC BP</a>
                        </li>


                    </ul>
                </div>
                <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4 search-bar mb-2 d-flex justify-content-end px-0">
                    <div className="input-group">
                        <select id="priceid" className="form-select" name={'priceRange'} onChange={onChangeFilter} value={priceRange}>
                            <option label="Tìm theo giá" value="">Tìm theo giá</option>
                            {listFilter && listFilter.length > 0 ?
                                listFilter.map(item =>
                                    <option label={item.Desctiption} value={item.ParamValue} key={item.ParamValue}>{item.Desctiption}</option>)
                                : null
                            }
                        </select>
                        {/*<div className="input-group-append">*/}
                        {/*    <button className="btn btn-search" type="button">*/}
                        {/*        <img src={searchIcon}/>*/}
                        {/*    </button>*/}
                        {/*</div>*/}
                    </div>
                </div>
            </div>
            <div className="row mb-4 acc-list">
                {isLoading ?
                    <div className="col-12 text-center">Loading...</div> :
                    data?.data && data.data.length > 0 ? data.data.map(item =>
                        <div className="col-6 col-md-4 col-lg-4 col-xl-4 col-xxl-4 mb-3 acc-item" key={item.ID}>
                            <div className="overlay">
                                <div className="acc-code">
                                    {item.AccCode}
                                </div>
                                <div className="acc-avatar">
                                    {!(playingAccs && playingAccs[item.ID]) && item.AccInfo ?
                                        <div className={"acc-info-overlay"}>
                                            <span>{getContentInHTML(item.AccInfo)}</span>
                                        </div> : null
                                    }
                                    {playingAccs && playingAccs[item.ID] ?
                                        <iframe src={item.youtube_link + "?autoplay=1&mute=1"} title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                                        :
                                        <img src={item.Avatar ? isUrl(item.Avatar) ? item.Avatar : `${STATIC_FILE_SERVER}${item.Avatar}` : default_acc_ava} alt={item.Title}/>
                                    }
                                </div>
                                <div className="acc-info">
                                    <div className="acc-name">
                                        <span style={{
                                            flex: '1',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                        }}>{item.loai_acc && (item.loai_acc === 2 || item.loai_acc === 3) ? `${item.Description} ${item.the_mau ? (item.the_mau + "Thẻ") : ""} ${item.the_fc ? (item.the_fc + "FC") : ""}` : item.Title}</span>
                                        {item.youtube_link ? <span style={{
                                            flex: '0 1 auto',
                                        }}>
                                            <div className={"show-video-player"} onClick={() => onClickPlayVideo(item)}>
                                                <a href="javascript:void(0)">Xem acc</a>
                                            </div> 
                                        </span> : null}
                                    </div>
                                    <div className="p-2 d-flex flex-column">
                                        {item.loai_acc && (item.loai_acc === 2 || item.loai_acc === 3) ?
                                            <div className="acc-detail mt-1">
                                                {item.ngay_svip ? <div className="info">Ngày SVIP: <span>{item.ngay_svip || 0}</span></div> : null}
                                                {/*{item.the_mau ? <div className="info">Thẻ CT: <span>{item.the_mau || 0}</span></div> : null}*/}
                                                {item.point ? <div className="info">Point: <span>{item.point || 0}</span></div> : null}
                                                {item.the_bv ? <div className="info">Thẻ BV: <span>{item.the_bv || 0}</span></div> : null}
                                                {item.the_ncl ? <div className="info">Thẻ NCL: <span>{item.the_ncl || 0}</span></div> : null}
                                                {item.the_30_percent ?
                                                    <div className="info">Thẻ 30%: <span>{item.the_30_percent || 0}</span></div> : null}

                                            </div>
                                            :
                                            <div className="acc-detail mt-1 text-center">
                                                <div className="info">{item.Description}</div>
                                            </div>
                                        }
                                        <div className="acc-price mt-2">
                                            <div className="d-flex mb-2" style={{flex: '1 1'}}>
                                                <div className="payment-method">ATM</div>
                                                <div className="price">
                                                    <div className="base-price" style={{
                                                        'color': item.sale_price && item.sale_price > 0 ? '#A9A9A9' : '#f92020',
                                                        'textDecoration': item.sale_price && item.sale_price > 0 ? 'line-through' : 'none',
                                                        'fontSize': item.sale_price && item.sale_price > 0 ? '14px' : '16px',
                                                    }}>{numberWithCommas(item.ATMPrice || 0)} FGC
                                                    </div>
                                                    {item.sale_price && item.sale_price > 0 ?
                                                        <div className="sale-price">{numberWithCommas(item.sale_price || 0)} FGC</div> : null}
                                                </div>
                                            </div>
                                            <div className="acc-action">
                                                <PurchaseAccountButton account={item} onPurchased={handlePurchaseAccount}/>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : <div className="col-12 text-center py-2">Không có dữ liệu</div>
                }

                <div className="col-12 mt-2 d-flex justify-content-end">
                    <Pagination pageCount={pageCount} onChangePage={handleChangePage}/>
                </div>
            </div>
        </div>
    );
}

export default AccList;