import {useSelector} from "react-redux";

const RightBanner = () => {
    const rightbanner1 = useSelector(state => state.app.settings.bannerright1);
    const rightbanner2 = useSelector(state => state.app.settings.bannerright2);
    const rightbanner3 = useSelector(state => state.app.settings.bannerright3);


    return (
        rightbanner1 || rightbanner2 || rightbanner3 ?
            <div className="right-banner d-none d-xxl-flex">
                {rightbanner1 ? <div className="right-banner-item">
                        <iframe src={rightbanner1} title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                    </div>
                    : null
                }
                {rightbanner2 ? <div className="right-banner-item">
                        <iframe src={rightbanner2} title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                    </div>
                    : null
                }
                {rightbanner3 ? <div className="right-banner-item">
                        <iframe src={rightbanner3} title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                    </div>
                    : null
                }
            </div>
            : null
    );
}
export default RightBanner;