import {useEffect, useState} from "react";
import moment from "moment";
import {secondsToHHmmss} from "../../utils/utils";

const CountDown = (props) => {
    const [time, setTime] = useState(null);
    const deadlineSecond = props.second;


    useEffect(() => {
        const countDownInteval = setInterval(() => {
            const now = moment().unix();
            const time = deadlineSecond - now;
            setTime(secondsToHHmmss(time));
        }, 1000)

        return () => {
            clearInterval(countDownInteval);
        }
    })

    return (
        <span className={props.className}>{time}</span>
    );
}
export default CountDown;