import vqmm from '../../assets/image/vongquaymayman.png';
import group42 from '../../assets/image/Group_42.png';
import spinImage from '../../assets/image/3ZzSgHr.png'
import Footer from "../../layout/Footer/Footer";
import './SpinWheel.css';
import {useEffect, useRef, useState} from "react";
import {drawWheel, resetWheel, startWheel} from "../../utils/wheel";
import Swal from "sweetalert2";
import {useSelector} from "react-redux";
import {useModal} from "react-modal-hook";
import PopupModal from "../../components/ModalComponent/PopupModal";
import {useSpinWheelMutation} from "../../redux/services/common";
import {useAuth} from "../../utils/hooks/useAuth";
import {useNavigate} from "react-router-dom";

const formatter = Intl.NumberFormat('en', {notation: 'compact'});

const SpinWheel = () => {
    const auth = useAuth();
    const [wheelSpinning, setWheelSpinning] = useState(false);
    const popup = useSelector(state => state.app.popups.find(item => item.PageCode === 'ThongBaoRandom'));
    const spinCost = useSelector(state => state.app.settings.spinCost);
    const [spinWheel, {spinning}] = useSpinWheelMutation();
    const navigate = useNavigate();

    const [showPopup, hidePopup] = useModal(() => (
        <PopupModal openModal={true} onClose={hidePopup} content={popup.Content}/>
    ), [popup.Content]);


    const countHasChangedRef = useRef(false);

    const startSpinning = () => {
        if (auth.user) {
            if (!wheelSpinning) {
                Swal.fire({
                    title: `Quay thưởng ${formatter.format(spinCost)}/1 lần`,
                    text: 'Chọn hình thức thanh toán',
                    input: 'radio',
                    inputOptions: {
                        'card': 'Card',
                        'atm': 'ATM'
                    },
                    inputValidator: (value) => {
                        if (!value) {
                            return 'Vui lòng chọn hình thức thanh toán'
                        }
                    },
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Có',
                    cancelButtonText: 'Không',
                }).then((result) => {
                    if (result.isConfirmed) {
                        doGetPrize(result.value);
                    }
                });
            } else {
                console.debug('the wheel is spinning !');
            }
        } else {
            Swal.fire({
                title: `Bạn chưa đăng nhập`,
                text: 'Vui lòng đăng nhập để sử dụng vòng quay',
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Đăng nhập',
            }).then((result) => {
                if (result.isConfirmed) {
                    navigate(`/login`);
                }
            });
        }
    }


    const doGetPrize = async (payment_method) => {
        const swalContent = {
            icon: 'error',
            title: 'Có lỗi xảy ra',
        }
        try {
            const response = await spinWheel({purchase_method: payment_method}).unwrap();
            if (response) {
                if (response.prize) {
                    const prize = response.prize.replace('Loai', "");
                    startWheel(parseInt(prize));
                    setWheelSpinning(true);
                } else {
                    swalContent.title = 'Có lỗi xảy ra';
                    swalContent.icon = 'error';
                    Swal.fire(swalContent);
                }
            }
        } catch (err) {
            swalContent.title = err.data?.message || err.data?.error || 'Có lỗi xảy ra';
            swalContent.icon = 'error';
            Swal.fire(swalContent);
        }


    }

    const onWheelStop = (indicatedSegment) => {
        Swal.fire({
            icon: 'success',
            title: 'Thành công',
            text: 'Bạn đã trúng thưởng, ' + indicatedSegment.text
        })
        setWheelSpinning(false);
        resetWheel();
    }

    useEffect(() => {
        // let timeout;
        if (!countHasChangedRef.current) { //using the flag to check if component mounted
            countHasChangedRef.current = true //updating flag
            drawWheel(onWheelStop);
            showPopup();
        }
    }, [])

    return (
        <div className={'back-lucky'} style={{backgroundImage: `url(${group42})`, backgroundSize: 'cover'}}>
            <div className={'container-fluid'}>
                <div className={'text-center'}>
                    <img style={{paddingTop: '100px'}} src={vqmm}/>
                </div>
                <div className={'content wheel'}>
                    <canvas id="myCanvas" width="601" height="601">
                        <p>Sorry, your browser doesn't support canvas. Please try another.</p>
                    </canvas>
                    <a id="spin_start" onClick={startSpinning}>
                        <img src={spinImage}/>
                    </a>
                </div>
            </div>
            <div className={'container-fluid border__top'}>
            </div>
            <Footer/>
        </div>
    );
}

export default SpinWheel;