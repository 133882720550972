import Modal from "react-bootstrap/Modal";
import {useEffect, useState} from "react";
import {STATIC_FILE_SERVER} from "../../config/app.config";
import {numberWithCommas} from "../../utils/utils";
import {faPlus, faMinus, faClock} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useCancelBidMutation, usePlaceBidMutation} from "../../redux/services/common";
import {useAuth} from "../../utils/hooks/useAuth";
import {useNavigate} from "react-router-dom";
import Swal from "sweetalert2";
import {useDispatch} from "react-redux";
import {updateUserCredit, updateUserCreditLock} from "../../redux/slices/authSlice";
import default_acc_ava from '../../assets/image/default-acc-ava.png';


const AuctionDetail = ({openModal, onClose, item}) => {
    const [show, setShow] = useState(false);
    const [currentPriceBid, setCurrentPriceBid] = useState(0);
    const [placeBidReq, {isPlacing}] = usePlaceBidMutation();
    const [cancelBidReq, {isCanceling}] = useCancelBidMutation();
    const navigate = useNavigate();
    const auth = useAuth();
    const dispatch = useDispatch();


    const handleClose = () => {
        setShow(false);
        onClose(false);
    };
    const onIncreaseBidMax = () => {
        setCurrentPriceBid(item.max_price);
    }
    const onDecreaseBidMin = () => {
        setCurrentPriceBid(item.current_price);
    }

    const onIncreaseBid = () => {
        if (currentPriceBid >= item.max_price) {
            return;
        }
        let priceIncreased = currentPriceBid + item.increase_step;
        if (priceIncreased >= item.max_price) {
            priceIncreased = item.max_price
        }
        setCurrentPriceBid(priceIncreased);
    }
    const onDecreaseBid = () => {
        if (currentPriceBid <= item.current_price) {
            return;
        }
        let priceDecreased = currentPriceBid - item.increase_step;
        if (priceDecreased <= item.current_price) {
            priceDecreased = item.current_price
        }
        setCurrentPriceBid(priceDecreased);
    }

    const onPlaceBid = async () => {
        if (auth.user) {
            const auction_id = item.id;
            const result = await Swal.fire({
                title: `Thông báo`,
                text: `Xác nhận đặt ${numberWithCommas(currentPriceBid)} FGC cho tài khoản này ?`,
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Có',
                cancelButtonText: 'Không',
            });
            if (result.isConfirmed) {
                try {
                    const bidInfo = await placeBidReq({auction_id, bid_amount: currentPriceBid}).unwrap();
                    if (bidInfo.is_winner == 1) {
                        Swal.fire({
                            title: `Thông báo`,
                            text: 'Chúc mừng bạn đã thắng cuộc đấu giá này, vui lòng truy cập hòm mail để nhận thông tin account',
                            confirmButtonColor: '#3085d6',
                            confirmButtonText: 'Ok',
                        });

                    } else {
                        const swalContent = {
                            icon: 'success',
                            title: 'Đặt giá thành công !',
                        }
                        Swal.fire(swalContent);
                    }
                    handleClose();
                    dispatch(updateUserCredit({value: parseInt(bidInfo.user_credit), method: 'atm'}));
                    dispatch(updateUserCreditLock({value: parseInt(bidInfo.user_credit_locked), method: 'atm'}));

                } catch (err) {
                    const swalContent = {
                        icon: 'error',
                        title: 'Có lỗi xảy ra',
                    }
                    swalContent.title = err.data?.message || err.data?.error || 'Có lỗi xảy ra';
                    swalContent.icon = 'error';
                    Swal.fire(swalContent);
                }
            }
        } else {
            Swal.fire({
                title: `Bạn chưa đăng nhập`,
                text: 'Vui lòng đăng nhập để đấu giá',
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Đăng nhập',
            }).then((result) => {
                if (result.isConfirmed) {
                    navigate(`/login`);
                }
            });
        }
    }
    const onCancelBid = async () => {
        if (auth.user) {
            const bidID = item.myBid?.id;
            const result = await Swal.fire({
                title: `Thông báo`,
                text: `Xác nhận hủy giá ${numberWithCommas(item.user_price)} đã đặt ?`,
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Có',
                cancelButtonText: 'Không',
            });
            if (result.isConfirmed) {
                let swalContent = {
                    icon: 'success',
                    title: 'Hủy đặt giá thành công !',
                }
                try {
                    const bidInfo = await cancelBidReq({id: bidID, auction_id: item.id}).unwrap();
                    Swal.fire(swalContent);
                    dispatch(updateUserCredit({value: parseInt(auth.user.credit) + parseInt(item.user_price), method: 'atm'}));
                    handleClose();
                } catch (err) {
                    swalContent.title = err.data?.message || err.data?.error || 'Có lỗi xảy ra';
                    swalContent.icon = 'error';
                    Swal.fire(swalContent);
                }
            }
        } else {
            Swal.fire({
                title: `Bạn chưa đăng nhập`,
                text: 'Vui lòng đăng nhập để đấu giá',
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Đăng nhập',
            }).then((result) => {
                if (result.isConfirmed) {
                    navigate(`/login`);
                }
            });
        }
    }

    useEffect(() => {
        setShow(openModal);
        if (auth.user && item.user_price) {
            setCurrentPriceBid(item.user_price);
        } else {
            setCurrentPriceBid(item.current_price);
        }
    }, [openModal, item]);
    return (
        <Modal
            enforceFocus={false}
            autoFocus={false}
            size="xl"
            show={show}
            onHide={handleClose}
            aria-labelledby="example-modal-sizes-title-lg"
            backdrop="static"
            className={'auction-detail-modal'}
        >
            <div className="modal-header">
                <h4 className="modal-title">Thông tin tài khoản {item.Title}</h4>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => handleClose(false)}>
                    <span aria-hidden="true">×</span>
                </button>
            </div>
            <Modal.Body>
                <div className="row auction-detail-container">
                    <div className="col-xl-6 auction-detail__account-info">
                        <div className="auction-detail-images">
                            {item.Avatar ?
                                <div className="__img"><img src={`${STATIC_FILE_SERVER}${item.Avatar}`} className="img-responsive"/></div> : null}
                            {item.Avatar2 ?
                                <div className="__img"><img src={`${STATIC_FILE_SERVER}${item.Avatar2}`} className="img-responsive"/></div> : null}
                            {item.Avatar3 ?
                                <div className="__img"><img src={`${STATIC_FILE_SERVER}${item.Avatar3}`} className="img-responsive"/></div> : null}
                            {!(item.Avatar || item.Avatar2 || item.Avatar3) ?
                                <div className="__img d-flex justify-content-center"><img src={default_acc_ava} className="img-responsive"/>
                                </div> : null}
                        </div>
                        <div className="mt-2">
                            <h5 className="text-center">
                                {item.Title} <span className="badge bg-info text-dark">{item.AccCode}</span>
                            </h5>
                        </div>
                        <div className="mt-2">
                            <div dangerouslySetInnerHTML={{__html: item.AccInfo}}></div>
                        </div>

                    </div>
                    <div className="col-xl-6">
                        <div className="auction-bid">
                            <div className={'bid-pricing my-2'}>
                                <span className="price-label">Giá khởi điểm</span>
                                <span className="price-value">{numberWithCommas(item.start_price)} FGC</span>
                            </div>
                            <div className={'bid-pricing  my-2'}>
                                <span className="price-label">Giá tối đa</span>
                                <span className="price-value">{numberWithCommas(item.max_price)} FGC</span>
                            </div>
                            <div className={'bid-pricing  my-2'}>
                                <span className="price-label">Bước giá</span>
                                <span className="price-value">{numberWithCommas(item.increase_step)} FGC</span>
                            </div>
                            <div className={'bid-pricing current-price my-2'}>
                                <span className="price-label">Giá hiện tại</span>
                                <span className="price-value">{numberWithCommas(item.current_price)} FGC</span>
                            </div>
                            <div className={'mt-2 mb-2 py-2 d-flex justify-content-center flex-column border-top'}>
                                {item.user_price ? <div>
                                    <p className="my-1 text-center">Bạn đã đặt</p>
                                </div> : null}
                                <div className="my-bid mt-1 mb-3 d-flex justify-content-center align-item-center">
                                    <button className="btn btn-danger btn-sm mx-1" onClick={onDecreaseBidMin}>Min</button>
                                    <button className="btn btn-danger btn-sm mx-1" onClick={onDecreaseBid}><FontAwesomeIcon icon={faMinus}/></button>
                                    <span className="mx-3">{numberWithCommas(currentPriceBid)}</span>
                                    <button className="btn btn-success btn-sm mx-1" onClick={onIncreaseBid}><FontAwesomeIcon icon={faPlus}/></button>
                                    <button className="btn btn-success btn-sm mx-1" onClick={onIncreaseBidMax}>Max</button>
                                </div>

                                <div className="d-flex flex-column">
                                    <button className="btn btn-primary w-100" onClick={onPlaceBid}>Đặt giá</button>
                                    {/*{item.user_price ?*/}
                                    {/*    <button className="btn btn-danger w-100 mt-2" onClick={onCancelBid}>Hủy đặt giá</button>*/}
                                    {/*    : null*/}
                                    {/*}*/}
                                </div>


                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
}
export default AuctionDetail;