export const numberWithCommas = (x) => {
    if (!x) {
        return 0;
    }
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
export const toDate = (date) => {
    const outputDate = new Date(date);
    return outputDate.toUTCString();
}

export const isUrl = (str) => {
    var r = new RegExp('^(?:[a-z+]+:)?//', '');
    return r.test(str);
}

export const getProviderName = (code) => {
    let providerName = "";
    switch (code) {
        case 'VTT':
            providerName = 'Viettel';
            break;
        case 'VNP':
            providerName = 'Vinaphone';
            break;
        case 'VMS':
            providerName = 'Mobifone';
            break;
        case 'VNM':
            providerName = 'Vietnamobile';
            break;
        case 'ZING':
            providerName = 'Zing';
            break;
        case 'GATE':
            providerName = 'Gate';
            break;
        case 'VTC':
            providerName = 'VCoin';
            break;
        case 'GRN':
            providerName = 'Garena';
            break;
        default:
            break;
    }

    return providerName;
}

export const getTopupStatus = (status) => {
    let statusTopup = "";
    switch (status) {
        case -1:
            statusTopup = "Đang chờ";
            break;
        case 0:
            statusTopup = "Lỗi";
            break;
        case 1:
            statusTopup = "Thành công";
            break;
        default:
            break;
    }
    return statusTopup;
}

export const secondsToHHmmss = (d) => {
    d = Number(d);
    var h = Math.floor(d / 3600);
    var m = Math.floor(d % 3600 / 60);
    var s = Math.floor(d % 3600 % 60);

    var hDisplay = h > 0 ? (h < 10 ? `0${h}` : h) + (":") : "00" + ":";
    var mDisplay = m > 0 ? (m < 10 ? `0${m}` : m) + (":") : "00" + ":";
    var sDisplay = s > 0 ? (s < 10 ? `0${s}` : s) + ("") : "00";
    return hDisplay + mDisplay + sDisplay;
}

export const getContentInHTML = (html) => {
    let content = html;
    try {
        const htmlContent = document.createElement('span');
        htmlContent.innerHTML = html;
        content = htmlContent.textContent || htmlContent.innerText;
    } catch (err) {
        console.error(err);
    }

    return content;

}