import {api} from "./api";

const uri = "account";

export const authApi = api.injectEndpoints({
    endpoints: (build) => ({
        logout: build.mutation({
            query: () => {
                return {
                    url: `${uri}/logout`,
                    method: 'POST'
                };
            }
        }),
        login: build.mutation({
            query: (body) => {
                return {
                    url: `${uri}/login`,
                    method: 'POST',
                    body
                };
            },
            invalidatesTags: (item) => [{type: 'Auction'}],
        }),
        auth: build.mutation({
            query: () => {
                return {
                    url: `${uri}/auth`,
                    method: 'POST'
                };
            }
        }),
        changePass: build.mutation({
            query: (body) => {
                return {
                    url: `${uri}/change-pass`,
                    method: 'POST',
                    body
                };
            }
        }),
        forgotPass: build.mutation({
            query: (body) => {
                return {
                    url: `${uri}/forgot-pass`,
                    method: 'POST',
                    body
                };
            }
        }),
        forgotPassReset: build.mutation({
            query: (body) => {
                return {
                    url: `${uri}/forgot-pass-reset`,
                    method: 'POST',
                    body
                };
            }
        }),
        checkPw2: build.mutation({
            query: (data) => {
                return {
                    url: `${uri}/check-pw2`,
                    method: 'POST',
                    body: data,
                };
            }
        }),
    })

})

export const {
    useLogoutMutation,
    useLoginMutation,
    useAuthMutation,
    useChangePassMutation,
    useForgotPassMutation,
    useForgotPassResetMutation,
    useCheckPw2Mutation,
} = authApi