import {Link} from "react-router-dom";
import {useGetTopupHistoryQuery} from "../../redux/services/common";
import searchIcon from '../../assets/image/search-icon.svg';
import {getTopupStatus, numberWithCommas, toDate} from "../../utils/utils";
import moment from "moment";


const TopupHistory = () => {

    const {data, error, isLoading} = useGetTopupHistoryQuery();

    return (
        <>
            <div className="row page-header mb-2">
                <div className="col-12 page-title" style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }}>
                    <h4>Lịch sử nạp tiền</h4>
                    <span>Tổng nạp : <b>{numberWithCommas(data?.statistic?.total || 0)}</b></span>
                </div>
            </div>
            <div className="row page-content">
                <div className="col-12">
                    <table className="table table-striped table-hover table-bordered tbl-history">
                        <thead>
                        <tr>
                            <th>Loại</th>
                            <th>Giá</th>
                            <th>Ngày</th>
                            <th>Trạng thái</th>
                            <th>Ghi chú</th>
                        </tr>
                        </thead>

                        <tbody>
                        {isLoading ?
                            <tr>
                                <td colSpan={100}>Loading...</td>
                            </tr> :
                            data?.data?.length > 0 ? data.data.map(item => <tr key={item.ID}>
                                    <td>{item.TypeRecharge === 1 ? "Thẻ cào" : "ATM"}</td>
                                    <td>{numberWithCommas(item.Amount)}</td>
                                    <td>{moment(item.RechargeCardDate).utcOffset('+0000').format("HH:mm:ss DD/MM/YYYY")}</td>
                                    <td>{getTopupStatus(item.Status)}</td>
                                    <td>{item.Note}</td>
                                </tr>)
                                :
                                <tr>
                                    <td colSpan={100}>Không có dữ liệu</td>
                                </tr>
                        }
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    );
}
export default TopupHistory;