import {Link} from "react-router-dom";

const Breadcrumb = (props) => {
    return (
        <div className="brum-top">
            <ul>
                <li><Link to={"/"} style={{color: "white"}}>Trang chủ</Link></li>
                <li style={{color: "#FCC93B"}}> &gt; {props.title}</li>
            </ul>
        </div>
    );
}

export default Breadcrumb;