import zalo_sp from '../../assets/image/zalo-support.png';

const ZaloSupport = () => {
    return (
        <div className="z-support-btn">
            <a href="https://zalo.me/0363020682" target="_blank">
                <img src={zalo_sp} className="img-fluid" alt="zalo support"/>
            </a>
        </div>
    );
}
export default ZaloSupport;