import {useDispatch, useSelector} from "react-redux";
import {useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import logo from "../../assets/image/AdminLTELogo.png";
import {Formik} from "formik";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEnvelope, faLock, faIdCard, faUser} from "@fortawesome/free-solid-svg-icons";
import './login.css';
import {useAddAccountMutation} from "../../redux/services/account";
import * as Yup from 'yup';
import Swal from "sweetalert2";
import lock from "../../assets/image/lock-closed.svg";
import user2 from "../../assets/image/user2.svg";
import mail2 from "../../assets/image/mail2.svg";
import creditCard from "../../assets/image/credit-card.svg";


const createAccSchema = Yup.object().shape({
    UserName: Yup.string().min(6, 'Tên đăng nhập quá ngắn, tối thiểu 6 ký tự').required('Vui lòng nhập tên đăng nhập').matches(/^[a-zA-Z0-9]+$/, 'Tên đăng nhập không hợp lệ'),
    Email: Yup.string().email('Email không hợp lệ').required('Vui lòng nhập email'),
    pin: Yup.string().min(6, 'Mật khẩu quá ngắn, tối thiểu 6 ký tự').required('Vui lòng nhập mật khẩu 2'),
    Password: Yup.string().min(6, 'Mật khẩu quá ngắn, tối thiểu 6 ký tự').required('Vui lòng nhập mật khẩu'),
    PasswordConfirm: Yup.string().required('Vui lòng xác nhận mật khẩu').test(
        'isValidPwConfirm', 'Xác nhận mật khẩu không trùng khớp', (value, context) => (value === context.parent.Password)
    ),
    Name: Yup.string().required("Vui lòng nhập tên")
});


const Signup = () => {
    const gRecaptchaSiteKey = useSelector(state => state.app.gRecaptchaSiteKey);
    const [isErrLogin, setErrLogin] = useState(null);
    const dispatch = useDispatch();
    const [addAccount, {isAdding}] = useAddAccountMutation();
    const navigate = useNavigate();
    const handleSubmit = async (values, actions) => {
        setErrLogin(null);
        window.grecaptcha.ready(async () => {
            try {
                const token = await window.grecaptcha.execute(gRecaptchaSiteKey, {action: 'submit'});
                if (token) {
                    const response = await addAccount({...values, gCaptchaToken: token}).unwrap();
                    if (response) {
                        Swal.fire({
                            icon: 'success',
                            title: 'Đăng ký thành công !',
                            text: 'Vui lòng truy cập hòm thư của bạn để kích hoạt tài khoản',
                            confirmButtonText: 'Quay về trang chủ',
                        }).then((result) => {
                            if (result.isConfirmed) {
                                navigate(`/`);
                            }
                        })
                    }
                } else {
                    setErrLogin("Verify captcha thất bại");
                }
            } catch (err) {
                setErrLogin(err.data?.message || err.data?.error || 'Có lỗi xảy ra');
            }
        });
    }

    return (
        <div className="login-form">
            <div className="login-form-header">
                <h1>Đăng ký tài khoản</h1>
                <p>Chào mừng bạn đến với <Link to={'/'}> ACCFIFA.VN </Link></p>
            </div>
            {isErrLogin && <p className={'text-danger'}>{isErrLogin}</p>}
            <Formik
                initialValues={{Email: "", Name: "", UserName: '', Password: '', PasswordConfirm: ''}}
                onSubmit={handleSubmit}
                validateOnBlur={false}
                validateOnChange={false}
                validationSchema={createAccSchema}
            >
                {props => (
                    <form onSubmit={props.handleSubmit}>
                        <div className={'form-group'}>
                            <img src={mail2} className="form-control-icon" alt='icon'/>
                            <input className="form-control has-icon" name={'Email'} onChange={props.handleChange} value={props.values.Email} placeholder="Email" type="text"/>
                            {props.errors.Email && <div className="form-text text-danger">{props.errors.Email}</div>}
                        </div>
                        <div className={'form-group'}>
                            <img src={creditCard} className="form-control-icon" alt='icon'/>
                            <input className="form-control has-icon" name={'Name'} onChange={props.handleChange} value={props.values.Name} placeholder="Họ tên" type="text"/>
                            {props.errors.Name && <div className="form-text text-danger">{props.errors.Name}</div>}
                        </div>
                        <div className={'form-group'}>
                            <img src={user2} className="form-control-icon" alt='icon'/>
                            <input className="form-control has-icon" name={'UserName'} onChange={props.handleChange} value={props.values.UserName} placeholder="Tên đăng nhập" type="text"/>
                            {props.errors.UserName ? <div className="form-text text-danger">{props.errors.UserName}</div> : <div className="form-text">Tối thiểu 6 ký tự, viết liền không dấu</div>}
                        </div>
                        <div className={'form-group'}>
                            <img src={lock} className="form-control-icon" alt='icon'/>
                            <input className="form-control has-icon" name={'Password'} onChange={props.handleChange} value={props.values.Password} placeholder="Mật khẩu" type="password"/>
                            {props.errors.Password && <div className="form-text text-danger">{props.errors.Password}</div>}
                        </div>
                        <div className={'form-group'}>
                            <img src={lock} className="form-control-icon" alt='icon'/>
                            <input className="form-control has-icon" name={'PasswordConfirm'} onChange={props.handleChange} value={props.values.PasswordConfirm} placeholder="Xác nhận mật khẩu" type="password"/>
                            {props.errors.PasswordConfirm && <div className="form-text text-danger">{props.errors.PasswordConfirm}</div>}
                        </div>
                        <div className={'form-group'}>
                            <img src={lock} className="form-control-icon" alt='icon'/>
                            <input className="form-control has-icon" name={'pin'} onChange={props.handleChange} value={props.values.pin} placeholder="Mật khẩu 2" type="password"/>
                            {props.errors.pin ? <div className="form-text text-danger">{props.errors.pin}</div> : <div className="form-text">Mật khẩu 2 của bạn, cần phải nhập nếu bạn muốn xem thông tin acc trong lịch sử giao dịch</div>}
                        </div>


                        <div className="form-group">
                            <button type="submit" className="btn w-100">Đăng ký</button>
                        </div>

                        <div className="help">
                            <p>Bạn đã có tài khoản ACCFIFA.VN? <Link to="/login">Đăng nhập</Link></p>
                        </div>
                    </form>


                )}


            </Formik>
        </div>
    );
}
export default Signup;