import {useDispatch, useSelector} from "react-redux";
import {useLoginMutation} from "../../redux/services/auth";
import {useState} from "react";
import {setCredentials} from "../../redux/slices/authSlice";
import {Formik} from "formik";
import {Link, useNavigate} from "react-router-dom";
import Swal from "sweetalert2";
import lock from '../../assets/image/lock-closed.svg';

const Login = (props) => {
    const gRecaptchaSiteKey = useSelector(state => state.app.gRecaptchaSiteKey);
    const [isErrLogin, setErrLogin] = useState(null);
    const dispatch = useDispatch();
    const [loginReq, {isLoggin}] = useLoginMutation();
    const navigate = useNavigate();
    const handleSubmit = async (values, actions) => {
        setErrLogin(null);
        window.grecaptcha.ready(async () => {
            try {
                const token = await window.grecaptcha.execute(gRecaptchaSiteKey, {action: 'submit'});
                if (token) {
                    const response = await loginReq({UserName: values.UserName, Password: values.Password, gCaptchaToken: token}).unwrap();
                    if (response) {
                        dispatch(setCredentials(response));
                        navigate(`/`);
                    }
                } else {
                    setErrLogin("Verify captcha thất bại");
                }
            } catch (err) {
                setErrLogin(err.data?.message || err.data?.error || 'Có lỗi xảy ra');
            }
        });
    }


    const validate = values => {
        const errors = {};

        if (!values.UserName) {
            errors.UserName = 'Vui lòng điền tên đăng nhập !';
        }
        if (!values.Password) {
            errors.Password = 'Vui lòng điền mật khẩu !';
        }

        return errors;
    };

    return (
        <div className="login-form">
            <div className="login-form-header">
                <h1>Đăng nhập</h1>
                <p>Chào mừng bạn quay lại với <Link to={'/'}> ACCFIFA.VN </Link></p>
            </div>
            {isErrLogin && <p className={'text-danger'}>{isErrLogin}</p>}
            <Formik
                initialValues={{UserName: '', Password: ''}}
                onSubmit={handleSubmit}
                validateOnBlur={false}
                validateOnChange={false}
                validate={validate}
            >
                {props => (
                    <form onSubmit={props.handleSubmit}>
                        <div className={'form-group'}>
                            <input className="form-control" name={'UserName'} onChange={props.handleChange} value={props.values.UserName} placeholder="Tên đăng nhập" type="text"/>
                            {props.errors.UserName && <div className="form-text text-danger">{props.errors.UserName}</div>}
                        </div>
                        <div className={'form-group'}>
                            <img src={lock} className="form-control-icon"/>
                            <input className="form-control has-icon" name={'Password'} onChange={props.handleChange} value={props.values.Password} type="password" placeholder={'Mật khẩu'}/>
                            {props.errors.Password && <div className="form-text text-danger">{props.errors.Password}</div>}
                        </div>
                        <div className="form-group text-end">
                            <Link to="/forgot-password" className="form-link">Quên mật khẩu</Link>
                        </div>

                        <div className="form-group">
                            <button type="submit" className="btn w-100">Đăng nhập</button>
                        </div>
                        <div className="help">
                            <p>Bạn chưa có tài khoản ACCFIFA.VN? <Link to="/signup">Đăng ký ngay</Link></p>
                        </div>

                    </form>
                )}
            </Formik>
        </div>
    );
}

export default Login;

