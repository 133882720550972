import {Link, useNavigate,  useSearchParams} from "react-router-dom";
import logo from "../../assets/image/AdminLTELogo.png";
import {Formik} from "formik";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faLock} from "@fortawesome/free-solid-svg-icons";
import * as Yup from "yup";
import {useSelector} from "react-redux";
import {useEffect, useState} from "react";
import Swal from "sweetalert2";
import {useForgotPassResetMutation} from "../../redux/services/auth";


const changePwSchema = Yup.object().shape({
    newPass: Yup.string().min(6, 'Mật khẩu quá ngắn, tối thiểu 6 ký tự').required('Vui lòng nhập mật khẩu mới'),
    confirmPass: Yup.string().required('Vui lòng xác nhận mật khẩu').test(
        'isValidPwConfirm', 'Xác nhận mật khẩu không trùng khớp', (value, context) => (value === context.parent.newPass)
    ),
});
const ResetPassword = () => {
    const gRecaptchaSiteKey = useSelector(state => state.app.gRecaptchaSiteKey);
    const [isErrChangePass, setErrChangePass] = useState(null);
    const [changePassRq, {isChanging}] = useForgotPassResetMutation();
    const [params, setSearchParams] = useSearchParams();
    const navigate = useNavigate();
    const handleSubmit = async (values, actions) => {
        setErrChangePass(null);
        const {newPass, oldPass} = values;

        window.grecaptcha.ready(async () => {
            try {
                const token = await window.grecaptcha.execute(gRecaptchaSiteKey, {action: 'submit'});
                if (token) {
                    const response = await changePassRq({oldPass, newPass, gCaptchaToken: token, id: params.get('id'), token: params.get('token')}).unwrap();
                    if (response) {
                        Swal.fire({
                            icon: 'success',
                            title: 'Đổi mật khẩu thành công !',
                            confirmButtonText: 'Quay về trang chủ',
                        }).then((result) => {
                            if (result.isConfirmed) {
                                navigate(`/`);
                            }
                        })
                    }
                } else {
                    setErrChangePass("Verify captcha thất bại");
                }
            } catch (err) {
                setErrChangePass(err.data?.message || err.data?.error || 'Có lỗi xảy ra');
            }
        });
    }
    
    return (
        <div className={'container-fluid login-wrapper'}>
            <div className={'login-box'}>
                <div className={'card card-outline card-primary'}>
                    <div className="card-header text-center">
                        <p className="h1"><b>Đổi mật khẩu</b></p>
                    </div>
                    <div className={'card-body '} style={{paddingBottom: '50px'}}>
                        <div className="text-center">
                            <Link to={'/'}>
                                <img className={'rounded-circle'} src={logo} alt={'logo'}/>
                            </Link>
                        </div>
                        {isErrChangePass && <p className={'text-danger'}>{isErrChangePass}</p>}
                        <Formik
                            initialValues={{newPass: '', confirmPass: ''}}
                            onSubmit={handleSubmit}
                            validateOnBlur={false}
                            validateOnChange={false}
                            validationSchema={changePwSchema}
                        >
                            {props => (
                                <form onSubmit={props.handleSubmit}>
                                    <div className={'form-group'}>
                                        <div className="input-group">
                                            <input className="form-control" name={'newPass'} onChange={props.handleChange} value={props.values.newPass} placeholder="Mật khẩu mới" type="password" autoComplete={'off'}/>
                                            <div className="input-group-append">
                                                <div className="input-group-text">
                                                    <FontAwesomeIcon icon={faLock}/>
                                                </div>
                                            </div>
                                        </div>
                                        {props.errors.newPass && <div className="form-text text-danger">{props.errors.newPass}</div>}
                                    </div>
                                    <div className={'form-group'}>
                                        <div className="input-group">
                                            <input className="form-control" name={'confirmPass'} onChange={props.handleChange} value={props.values.confirmPass} placeholder="Xác nhận mật khẩu mới" type="password" autoComplete={'off'}/>
                                            <div className="input-group-append">
                                                <div className="input-group-text">
                                                    <FontAwesomeIcon icon={faLock}/>
                                                </div>
                                            </div>
                                        </div>
                                        {props.errors.confirmPass && <div className="form-text text-danger">{props.errors.confirmPass}</div>}
                                    </div>
                                    <div className={'row'}>
                                        <div className={'col-md-12'}>
                                            <button type="submit" className="btn btn-primary btn-block">Đổi mật khẩu</button>
                                        </div>
                                    </div>
                                </form>
                            )}
                        </Formik>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ResetPassword;