import React, {useEffect} from 'react';
import {Route, Routes} from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import './App.scss';
import {useSelector} from "react-redux";
import Layout from "../layout/Layout";
import Home from "../views/Home/Home";
import Page from "../views/Page/Page";
import AccountDetail from "../views/AccountDetail/AccountDetail";
import Login from "../views/Auth/Login";
import Signup from "../views/Auth/Signup";
import NotFound from "../views/Page/NotFound";
import ActivateSuccess from "../views/Auth/ActivateSuccess";
import ChangePass from "../views/Auth/ChangePass";
import TopupHistory from "../views/Page/TopupHistory";
import ProtectedView from "../layout/ProtectedView";
import TransactionHistory from "../views/Page/TransactionHistory";
import SpinWheel from "../views/SpinWheel/SpinWheel";
import ResetPassword from "../views/Auth/ResetPassword";
import ForgotPassword from "../views/Auth/ForgotPassword";
import Auction from "../views/Auction/Auction";
import TopRecharge from "../views/TopRecharge/TopRecharge";
import AuthLayout from "../views/Auth/AuthLayout";
import PageLayout from "../views/Page/PageLayout";
import Topup from "../views/Page/Topup";
import TransactionHistoryAll from "../views/Page/TransactionHistoryAll";


function App() {
    const isLoading = useSelector(state => state.app.loading);

    return isLoading ? <div>loading</div> : (
        <>
            <Routes>
                <Route element={<Layout/>}>
                    <Route index element={<Home/>}/>
                    <Route path={'/account-detail/:id'} element={<AccountDetail/>}/>
                    <Route element={<PageLayout/>}>
                        <Route path={'/dieu-khoan'} element={<Page code={'DieuKhoan'}/>}/>
                        <Route path={'/bao-mat'} element={<Page code={'BaoMatTaiKhoan'}/>}/>
                        <Route path={'/cong-tac-vien'} element={<Page code={'CTV'}/>}/>
                        <Route path={'/nap-tien'} element={<Topup/>}/>
                        <Route path={'/lich-su-mua'} element={<TransactionHistoryAll/>}/>
                    </Route>
                    <Route path={'/user'} element={<ProtectedView/>}>
                        <Route element={<PageLayout/>}>
                            <Route path={'topup-history'} element={<TopupHistory/>}/>
                            <Route path={'transaction-history'} element={<TransactionHistory/>}/>
                        </Route>
                    </Route>

                </Route>
                <Route element={<Layout/>}>
                    <Route path={'/dau-gia'} element={<Auction/>}/>
                    <Route path={'/wheel-spin'} element={<SpinWheel/>}/>
                    {/*<Route path={'/top-nap-the'} element={<TopRecharge/>}/>*/}
                </Route>
                <Route element={<AuthLayout/>}>
                    <Route path={'/login'} element={<Login/>}/>
                    <Route path={'/forgot-password'} element={<ForgotPassword/>}/>
                    <Route path={'/signup'} element={<Signup/>}/>
                    <Route path={'/activate-success'} element={<ActivateSuccess/>}/>
                    <Route path={'/change-pass'} element={<ChangePass/>}/>
                    <Route path={'/forgot-password-reset'} element={<ResetPassword/>}/>
                </Route>

                <Route path="*" element={<NotFound/>}/>
            </Routes>
        </>
    )
}

export default App;
