import {useGetTransactionHistoryQuery} from "../../redux/services/common";
import {getTopupStatus, numberWithCommas, toDate} from "../../utils/utils";
import AccinfoComponent from "./sub_view/AccinfoComponent";
import {useState} from "react";
import {useModal} from "react-modal-hook";
import eye from '../../assets/image/eye.svg';
import {Button, Form, OverlayTrigger, Popover} from "react-bootstrap";
import moment from "moment";
import {useAuth} from "../../utils/hooks/useAuth";
import {useCheckPw2Mutation} from "../../redux/services/auth";


const TransactionHistory = () => {
    const auth = useAuth();
    const {data, error, isLoading} = useGetTransactionHistoryQuery();
    const [checkPw2Req] = useCheckPw2Mutation();
    const [selectedItem, setSelectedItem] = useState(null);
    const [hasEnteredPw2, setHasEnteredPw2] = useState(false);
    const [pw2, setPw2] = useState("");

    const hasPin = auth.user.hasPin;


    // const [showCUModal, hideCUModal] = useModal(() => (
    //     <AccinfoComponent openModal={true} onClose={hideCUModal} item={selectedItem}/>
    // ), [selectedItem]);

    // const viewAccinfo = (transaction) => {
    //     setSelectedItem(transaction);
    //     showCUModal();
    // }

    const onCheckPw2 = async () => {
        try {
            const res = await checkPw2Req({pw2}).unwrap();
            if (res?.message === 'ok') {
                setHasEnteredPw2(true);
                setPw2("");
            } else {
                throw new Error('Mật khẩu không đúng !');
            }
        } catch (err) {
            console.error(err);
        }
    }
    return (
        <>
            <div className="row page-header mb-2">
                <div className="col-12 page-title" style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }}>
                    <h4>Lịch sử giao dịch</h4>
                    <span>Tổng đã mua : <b>{numberWithCommas(data?.statistic?.atm?.totalAccount || 0)}</b></span>
                </div>
            </div>
            <div className="row page-content">
                <div className="col-12" style={{overflowY: 'auto'}}>
                    <table className="table table-striped table-hover table-bordered tbl-history">
                        <thead>
                        <tr>
                            <th>Thời gian</th>
                            <th>Mã tài khoản</th>
                            <th>Giá</th>
                            <th>Tên</th>
                            <th style={{width: '60px'}}></th>
                        </tr>
                        </thead>
                        <tbody>
                        {isLoading ?
                            <tr>
                                <td colSpan={100}>Loading...</td>
                            </tr> :
                            data?.data?.length > 0 ? data.data.map(item => <tr key={item.ID}>
                                    <td>{moment(item.ExchangeDate).utcOffset('+0000').format("HH:mm:ss DD/MM/YYYY")}</td>
                                    <td>{item.AccCode}</td>
                                    <td>{numberWithCommas(item.price ?? 0)}</td>
                                    <td>{item.loai_acc && (item.loai_acc === 2 || item.loai_acc === 3) ? `${item.Description} ${item.the_mau}Thẻ Ngày SVIP:${item.ngay_svip}  Point:${item.point} Thẻ BV:${item.the_bv} Thẻ NCL:${item.the_ncl} Thẻ 30%${item.the_30_percent}` : item.Title}</td>
                                    <td className="tbl-action">
                                        {!!item.acc_info &&
                                            <OverlayTrigger trigger={['click']} placement="bottom" overlay={
                                                <Popover id={`popover-positioned-${item.ID}`} className="account-info-popover">
                                                    <Popover.Header as="h3">Thông tin tài khoản</Popover.Header>
                                                    <Popover.Body>
                                                        {!hasEnteredPw2 && hasPin ?
                                                            <div>
                                                                <Form.Label htmlFor="inputPassword5">Nhập mật khẩu 2 của bạn để xem thông tin acc</Form.Label>
                                                                <Form.Control
                                                                    type="password"
                                                                    id="inputPassword5"
                                                                    aria-describedby="passwordHelpBlock"
                                                                    value={pw2}
                                                                    onChange={(e) => setPw2(e.target.value)}
                                                                />

                                                                <Button className={'mt-2'} variant="primary" onClick={onCheckPw2}>Xác nhận</Button>
                                                            </div>
                                                            : item.acc_info
                                                        }
                                                    </Popover.Body>
                                                </Popover>
                                            }>
                                                <button type="button" className="btn btn-link" title={'Xem thông tin acc'}>
                                                    <img src={eye} className="icon"/>
                                                </button>
                                            </OverlayTrigger>
                                        }

                                    </td>
                                </tr>)
                                :
                                <tr>
                                    <td colSpan={100}>Không có dữ liệu</td>
                                </tr>
                        }
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    );
}
export default TransactionHistory;