import loginImage from "../../assets/image/image 68.png";
import {Link, Route, Outlet} from "react-router-dom";
import './login.scss';

const AuthLayout = () => {
    return (
        <div className="w-100 h-100">
            <div className={'container-xxl login-wrapper'}>
                <div className="row h-100 overflow-hidden">
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6 login-img d-none d-lg-block d-xl-block d-xxl-block">
                        <div className="position-relative">
                            <div className="text-overlay">
                                <p className="t1">ACCFIFA.VN</p>
                                <p className="t2">UY TÍN- CHẤT LƯỢNG- GIÁ RẺ</p>
                            </div>
                            <img src={loginImage}/>
                        </div>
                    </div>
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6 login-panel">
                        <Outlet/>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default AuthLayout;
