import {Link, useNavigate} from "react-router-dom";
import logo from "../../assets/image/AdminLTELogo.png";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faLock} from "@fortawesome/free-solid-svg-icons";

import * as Yup from 'yup';
import {Formik} from "formik";
import {useChangePassMutation} from "../../redux/services/auth";
import Swal from "sweetalert2";
import {useAuth} from "../../utils/hooks/useAuth";
import {Navigate} from "react-router-dom";
import {useState} from "react";
import {useSelector} from "react-redux";
import lock from "../../assets/image/lock-closed.svg";


const changePwSchema = Yup.object().shape({
    oldPass: Yup.string().required('Vui lòng nhập mật khẩu cũ'),
    newPass: Yup.string().min(6, 'Mật khẩu quá ngắn, tối thiểu 6 ký tự').required('Vui lòng nhập mật khẩu mới'),
    confirmPass: Yup.string().required('Vui lòng xác nhận mật khẩu').test(
        'isValidPwConfirm', 'Xác nhận mật khẩu không trùng khớp', (value, context) => (value === context.parent.newPass)
    ),
});

const ChangePass = () => {
    const gRecaptchaSiteKey = useSelector(state => state.app.gRecaptchaSiteKey);
    const auth = useAuth();
    const [isErrChangePass, setErrChangePass] = useState(null);
    const [changePassRq, {isChanging}] = useChangePassMutation();
    const navigate = useNavigate();
    const handleSubmit = async (values, actions) => {
        setErrChangePass(null);
        const {newPass, oldPass} = values;

        window.grecaptcha.ready(async () => {
            try {
                const token = await window.grecaptcha.execute(gRecaptchaSiteKey, {action: 'submit'});
                if (token) {
                    const response = await changePassRq({oldPass, newPass, gCaptchaToken: token}).unwrap();
                    if (response) {
                        Swal.fire({
                            icon: 'success',
                            title: 'Đổi mật khẩu thành công !',
                            confirmButtonText: 'Quay về trang chủ',
                        }).then((result) => {
                            if (result.isConfirmed) {
                                navigate(`/`);
                            }
                        })
                    }
                } else {
                    setErrChangePass("Verify captcha thất bại");
                }
            } catch (err) {
                setErrChangePass(err.data?.message || err.data?.error || 'Có lỗi xảy ra');
            }
        });
    }

    return (
        <>
            {auth.user ?
                <div className="login-form">
                    <div className="login-form-header">
                        <h1>Đổi mật khẩu</h1>
                    </div>
                    {isErrChangePass && <p className={'text-danger'}>{isErrChangePass}</p>}
                    <Formik
                        initialValues={{oldPass: "", newPass: '', confirmPass: ''}}
                        onSubmit={handleSubmit}
                        validateOnBlur={false}
                        validateOnChange={false}
                        validationSchema={changePwSchema}
                    >
                        {props => (
                            <form onSubmit={props.handleSubmit}>
                                <div className={'form-group'}>
                                    <img src={lock} className="form-control-icon"/>
                                    <input className="form-control has-icon" name={'oldPass'} onChange={props.handleChange} value={props.values.oldPass} type="password" placeholder={'Mật khẩu'}/>
                                    {props.errors.oldPass && <div className="form-text text-danger">{props.errors.oldPass}</div>}
                                </div>
                                <div className={'form-group'}>
                                    <img src={lock} className="form-control-icon"/>
                                    <input className="form-control has-icon" name={'newPass'} onChange={props.handleChange} value={props.values.newPass} type="password" placeholder={'Mật khẩu'}/>
                                    {props.errors.newPass && <div className="form-text text-danger">{props.errors.newPass}</div>}
                                </div>
                                <div className={'form-group'}>
                                    <img src={lock} className="form-control-icon"/>
                                    <input className="form-control has-icon" name={'confirmPass'} onChange={props.handleChange} value={props.values.confirmPass} type="password" placeholder={'Mật khẩu'}/>
                                    {props.errors.confirmPass && <div className="form-text text-danger">{props.errors.confirmPass}</div>}
                                </div>
                                <div className="form-group text-end">
                                    <Link to="/" className="form-link">Quay về trang chủ</Link>
                                </div>

                                <div className="form-group">
                                    <button type="submit" className="btn w-100">Đổi mật khẩu</button>
                                </div>
                            </form>
                        )}
                    </Formik>
                </div>
                : <Navigate to={'/login'}/>
            }
        </>
    );
}

export default ChangePass;