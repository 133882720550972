import {Outlet} from "react-router-dom";
import './Page.scss';
const PageLayout = () => {

    return (
        <div className="layout-padding user-page flex-grow-1">
            <Outlet/>
        </div>
    );
}
export default PageLayout;