import {useSelector} from "react-redux";
import AppLogo from "./AppLogo";
import AppNavigator from "./AppNavigator";
import './Header.scss';

const Header = () => {
    const chuchay = useSelector(state => state.app.settings.chuchay);

    return (
        <header className="app-header">
            <div className="row">
                <div className="col-12 run-text layout-padding">
                    <marquee>{chuchay}</marquee>
                </div>
            </div>
            <AppLogo/>
            <AppNavigator/>
        </header>
    );
}

export default Header;