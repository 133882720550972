import banner from '../../../assets/image/Banner2 1.png';
import './Banner.scss';
import {useSelector} from "react-redux";
import {useRef, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronLeft, faChevronRight} from "@fortawesome/free-solid-svg-icons";


const Banner = () => {
    const banners = useSelector(state => state.app.banner);
    const currentCategory = useSelector(state => state.app.currentCategory);
    const ref = useRef();

    const onScrollNext = () => {
        if (ref.current) {
            const scrollElem = ref.current;
            const children = scrollElem.querySelector('.video-item');
            const width = children.clientWidth;
            scrollElem.scrollTo({left: scrollElem.scrollLeft + width, behavior: 'smooth'});
        }
    }
    const onScrollPrev = () => {
        if (ref.current) {
            const scrollElem = ref.current;
            const children = scrollElem.querySelector('.video-item');
            const width = children.clientWidth;
            scrollElem.scrollTo({left: scrollElem.scrollLeft - width, behavior: 'smooth'});
        }
    }

    return (
        <div className="banner-header">
            <img src={banner} alt="banner" style={{opacity: !banners || banners.length === 0 ? 1 : 0}}/>
            {banners && banners.length > 0 ? <div className="prev">
                <button className="btn btn-default btn-sm" onClick={onScrollPrev}>
                    <FontAwesomeIcon icon={faChevronLeft}/>
                </button>
            </div> : null}
            {banners && banners.length > 0 ? <div className="next">
                <button className="btn btn-default btn-sm" onClick={onScrollNext}>
                    <FontAwesomeIcon icon={faChevronRight}/>
                </button>
            </div> : null}
            {banners && banners.length > 0 ?
                <div className="text-overlay" ref={ref}>
                    {banners.map(banner =>
                        (currentCategory === 2 && banner.ParamCode.includes('slideAccBp'))
                        || (currentCategory === 3 && banner.ParamCode.includes('slideAccMothe'))
                        || (currentCategory === 1 && banner.ParamCode.includes('slideAccDH')) ?
                            <div className="video-item col-12 col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-12" key={banner.ID}>
                                <iframe src={banner.ParamValue} title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                            </div>
                            : null
                    )}
                </div>
                : null}
        </div>
    );
}
export default Banner;