import {Link, useNavigate, useSearchParams} from "react-router-dom";
import React, {useEffect} from "react";


const ActivateSuccess = () => {
    const [params, setSearchParams] = useSearchParams();
    const navigate = useNavigate();

    useEffect(() => {
        if (!params.get('id') && !params.get('hash')) {
            navigate(`/`)
        }
    }, [])
    return (
        <div className="jumbotron text-center w-100 h-100" style={{position: 'absolute'}}>
            <h1 className="display-4">Cảm ơn bạn đã tin tưởng sử dụng dịch vụ của chúng tôi!</h1>
            <p className="lead">Tài khoản của bạn đã được kích hoạt thành công, vui lòng <Link to={'/login'}>đăng nhập</Link> để tiếp tục sử dụng dịch vụ
            </p>
            <hr/>
            <p className="lead">
                <Link to={'/'} className="btn btn-primary btn-sm" role="button">Quay lại trang chủ</Link>
            </p>
        </div>
    );
};
export default ActivateSuccess;