import {isUrl, numberWithCommas} from "../../utils/utils";
import {useGetAuctionListQuery} from "../../redux/services/common";
import AuctionItem from "../../views/Auction/AuctionItem";
// import '../../views/Auction/Auction.css';
import AutoRefetchTimer from "../../views/Auction/AutoRefetchTimer";
import {Link} from "react-router-dom";
import arrDown from '../../assets/image/ar-down.svg';
import './AuctionWidget.scss';
import {STATIC_FILE_SERVER} from "../../config/app.config";
import default_acc_ava from "../../assets/image/default-acc-ava.png";

const AuctionWidget = () => {
    const {data, error, isLoading, refetch} = useGetAuctionListQuery();
    const onRefetch = () => {
        refetch();
    }

    return (
        <div className="auction-widget">
            <div className="auction-title">
                <div className="title text-uppercase">
                    <span className="text-green me-1 font-weight-500">TÀI KHOẢN</span>
                    <span className="text-red font-weight-700">ĐANG ĐẤU GIÁ</span>
                </div>
                <div className="view-detail">
                    <Link to={'/dau-gia'}>View All <img src={arrDown} alt="view all"/></Link>
                </div>
            </div>
            <div className="auction-items-widget">
                {data && data.length > 0 ? data.map(item =>
                        <div className="auction-item-widget">
                            <AuctionItem auctionData={item} key={item.id}/>
                        </div>
                    )
                    :
                    <h5 className="text-center w-100 mb-0">Hiện không có cuộc đấu giá nào </h5>
                }
            </div>
        </div>




        // <div className="card--content">
        //     <div className="title__content text-center pt-3 text-uppercase">Tài khoản đang đấu giá</div>
        //     <div className="content__detail text-left auction-container auction-widget-list font-weight-normal">
        //         <div className="pl-2">
        //             <AutoRefetchTimer onAction={onRefetch}/>
        //         </div>
        //         <div className="ipad_size auction-list " style={{minHeight: 'auto',overflowY: 'auto',maxHeight: '550px',marginRight: '8px'}}>
        //             {isLoading ?
        //                 <div className="col-12">
        //                     Loading
        //                 </div> :
        //                 data && data.length > 0 ? data.map(item =>
        //                         <div className="col-12 mb-2" key={item.id}>
        //                             <AuctionItem auctionData={item}/>
        //                         </div>
        //                     ) :
        //                     <div className="col-12">
        //                         Hiện không có cuộc đấu giá nào đang diễn ra
        //                     </div>
        //             }
        //         </div>
        //     </div>
        // </div>
    );
}
export default AuctionWidget;