import PhotoSwipe from 'photoswipe';
import 'photoswipe/dist/photoswipe.css';


const options = {
    mainClass: 'pswp--zindex',
    dataSource: [],
    showHideAnimationType: 'fade'
};

const getMeta = (url, onLoad) => {
    const img = new Image();
    img.addEventListener("load", function () {
        onLoad(...[this.naturalWidth, this.naturalHeight])
    });
    img.src = url;
}

export const openPhoto = (url) => {
    options.index = 0; // defines start slide index

    getMeta(url, (width, height) => {
        options.dataSource = [{
            src: url,
            width: width,
            height: height,
        }]
        const pswp = new PhotoSwipe(options);
        pswp.init(); // initializing PhotoSwipe core adds it to DOM
    });
}