import {configureStore} from '@reduxjs/toolkit';
import {api} from './services/api'
import {setupListeners} from "@reduxjs/toolkit/query";
import authReducer from './slices/authSlice';
import appReducer from './slices/appSlice';

export const store = configureStore({
    reducer: {
        [api.reducerPath]: api.reducer,
        auth: authReducer,
        app: appReducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(api.middleware),
});

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch)