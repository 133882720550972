import {usePurchaseAccountMutation} from "../../redux/services/common";
import Swal from "sweetalert2";
import {useAuth} from "../../utils/hooks/useAuth";
import {useNavigate} from "react-router-dom";

const PurchaseAccountButton = ({account, onPurchased}) => {
    const auth = useAuth();
    const [purchaseReq, {purchasing}] = usePurchaseAccountMutation();
    const navigate = useNavigate();

    const onBuyAccount = () => {
        if (auth.user) {
            Swal.fire({
                heightAuto: false,
                title: `Mua tài khoản ${account.Title}`,
                text: 'Chọn hình thức thanh toán',
                input: 'radio',
                inputOptions: {
                    'atm': 'ATM'
                },
                inputValidator: (value) => {
                    if (!value) {
                        return 'Vui lòng chọn hình thức thanh toán'
                    }
                },
                showDenyButton: true,
                showCancelButton: true,
                returnInputValueOnDeny: true,
                confirmButtonText: 'Mua ngay',
                denyButtonText: `Mua với giảm giá`,
                cancelButtonText: "Hủy",
            }).then((result) => {
                const purchaseMethod = result.value;
                if (result.isConfirmed) {
                    doBuyAccount(null, purchaseMethod);
                } else if (result.isDenied) {
                    Swal.fire({
                        input: 'text',
                        inputAttributes: {
                            autocapitalize: 'off'
                        },
                        title: `Nhập mã giảm giá`,
                        showCancelButton: true,
                        confirmButtonText: 'Xác nhận',
                        cancelButtonText: "Hủy",
                        inputValidator: (value) => {
                            if (!value) {
                                return 'Bạn cần nhập mã giảm giá!'
                            }
                        }
                    }).then((result) => {
                        if (result.isConfirmed) {
                            doBuyAccount(result.value, purchaseMethod);
                        }
                    })
                }
            })
        } else {
            navigate('/login');
        }

    }
    const doBuyAccount = async (promo_code, purchaseMethod) => {
        // purchase account here
        const swalContent = {
            icon: 'error',
            title: 'Có lỗi xảy ra',
        }
        try {
            const response = await purchaseReq({id: account.ID, body: {promo_code, purchase_method: purchaseMethod}}).unwrap();
            if (response) {
                swalContent.title = "Mua thành công !";
                swalContent.icon = "success";
            }
            onPurchased();
        } catch (err) {
            swalContent.title = err.data?.message || err.data?.error || 'Có lỗi xảy ra';
            swalContent.icon = 'error';
        } finally {
            Swal.fire(swalContent);
        }
    }

    return (
        <button type="button" className="btn btn-third btn-purchase" onClick={onBuyAccount}>Mua ngay</button>
    );
}
export default PurchaseAccountButton;