import Footer from "../../layout/Footer/Footer";
import Breadcrumb from "../../components/BreadcumbComponent/Breadcrumb";
import {useGetAccountDetailQuery, useGetPageQuery} from "../../redux/services/common";
import {useSelector} from "react-redux";

const Page = ({code}) => {
    const data = useSelector(state => state.app.popups.find(item => item.PageCode === code));

    return (
        <>
            <div className="row page-header mb-2">
                <div className="col-12 page-title">
                    <h4>{data?.Title}</h4>
                </div>
            </div>
            <div className="row page-content">
                <div className="col-12">
                    {
                        data ?
                            <div dangerouslySetInnerHTML={{__html: data.Content}}></div> :
                            <div>Không tìm thấy trang này</div>
                    }
                </div>
            </div>
        </>
    );
}
export default Page;