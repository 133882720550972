import {STORAGE_TOKEN_KEY} from "../config/app.config";
import {store} from "../redux/store";
import {setCredentials} from "../redux/slices/authSlice";
import {appLoaded, setAppCardData, setAppData} from "../redux/slices/appSlice";

export const startApp = async () => {
    const token = window.localStorage.getItem(STORAGE_TOKEN_KEY);
    if (token) {
        try {
            const response = await window.fetch('/api/web/account/auth', {
                    method: 'POST',
                    headers:
                        {'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`}
                }
            );
            const data = await response.json();
            if (response.ok) {
                store.dispatch(setCredentials({token, user: data.user}));
            } else {
                throw new Error(response.statusText);
            }
        } catch (err) {
            console.error('user is unauthorized');
            window.localStorage.removeItem(STORAGE_TOKEN_KEY);
        }
    }

    try {
        const response = await window.fetch('/api/web/app-settings', {
                method: 'GET',
                headers: {'Content-Type': 'application/json'}
            }
        );
        const data = await response.json();
        if (response.ok) {
            const script = document.createElement("script");
            script.src = "https://www.google.com/recaptcha/api.js?render=" + data.gRecaptchaSiteKey;
            document.body.appendChild(script);
            store.dispatch(setAppData(data));

            window.fetch('/api/web/topup/list-card', {
                    method: 'GET',
                    headers: {'Content-Type': 'application/json'}
                }
            ).then(async (res) => {
                const dataCard = await res.json();
                if (res.ok) {
                    store.dispatch(setAppCardData(dataCard));
                }
            });
        } else {
            throw new Error(response.statusText);
        }
    } catch (err) {
        console.error(err);
    }

    store.dispatch(appLoaded());
}