import {Link, useNavigate} from "react-router-dom";
import {Formik} from "formik";
import Swal from "sweetalert2";
import {useState} from "react";
import {useForgotPassMutation} from "../../redux/services/auth";
import {useSelector} from "react-redux";
import mail2 from "../../assets/image/mail2.svg";

const ForgotPassword = () => {
    const [isErrLogin, setErrLogin] = useState(null);
    const [forgotPassReq, {isSending}] = useForgotPassMutation();
    const gRecaptchaSiteKey = useSelector(state => state.app.gRecaptchaSiteKey);
    const navigate = useNavigate();


    const handleSubmit = (values) => {
        setErrLogin(null);
        window.grecaptcha.ready(async () => {
            try {
                const token = await window.grecaptcha.execute(gRecaptchaSiteKey, {action: 'submit'});
                if (token) {
                    const response = await forgotPassReq({Email: values.Email, gCaptchaToken: token}).unwrap();
                    if (response) {
                        Swal.fire({
                            icon: 'success',
                            title: 'Yêu cầu đổi mật khẩu thành công !',
                            text: 'Vui lòng truy cập hòm thư của bạn để thực hiện đổi mật khẩu mới',
                            confirmButtonText: 'Quay về trang chủ',
                        }).then((result) => {
                            if (result.isConfirmed) {
                                navigate(`/`);
                            }
                        })
                    }
                } else {
                    setErrLogin("Verify captcha thất bại");
                }
            } catch (err) {
                setErrLogin(err.data?.message || err.data?.error || 'Có lỗi xảy ra');
            }
        });
    }

    const validate = values => {
        const errors = {};

        if (!values.Email) {
            errors.Email = 'Vui lòng điền Email !';
        }

        return errors;
    };


    return (
        <div className="login-form">
            <div className="login-form-header">
                <h1>Quên mật khẩu</h1>
                <p>Lấy lại mật khẩu qua email</p>
            </div>
            {isErrLogin && <p className={'text-danger'}>{isErrLogin}</p>}
            <Formik
                initialValues={{Email: ''}}
                onSubmit={handleSubmit}
                validateOnBlur={false}
                validateOnChange={false}
                validate={validate}
            >
                {props => (
                    <form onSubmit={props.handleSubmit}>
                        <div className={'form-group'}>
                            <img src={mail2} className="form-control-icon" alt='icon'/>
                            <input className="form-control has-icon" name={'Email'} onChange={props.handleChange} value={props.values.Email} placeholder="Email đã đăng ký" type="text"/>
                            {props.errors.Email && <div className="form-text text-danger">{props.errors.Email}</div>}
                        </div>
                        <div className="form-group text-end">
                            <Link to="/login" className="form-link">Đăng nhập</Link>
                        </div>

                        <div className="form-group">
                            <button type="submit" className="btn w-100">Gửi yêu cầu thay đổi</button>
                        </div>
                    </form>
                )}
            </Formik>
        </div>

    );
}

export default ForgotPassword;