import {createSlice} from '@reduxjs/toolkit'
import {STORAGE_TOKEN_KEY} from "../../config/app.config";


const authSlice = createSlice({
    name: 'auth',
    initialState: {user: null, token: null},
    reducers: {
        setCredentials: (state, {payload: {user, token}}) => {
            state.user = user;
            state.token = token;
            window.localStorage.setItem(STORAGE_TOKEN_KEY, token);
        },
        unsetCredentials(state, action) {
            state.user = null;
            state.token = null;
            window.localStorage.removeItem(STORAGE_TOKEN_KEY);
        },
        setToken(state, {payload: {token}}) {
            state.token = token;
        },
        updateUserCredit(state, {payload}) {
            if (payload.method === 'card') {
                state.user.credit = payload.value;
            } else {
                state.user.credit_atm = payload.value;
            }
        },
        updateUserCreditLock(state, {payload}) {
            if (payload.method === 'card') {
                state.user.credit = payload.value;
            } else {
                state.user.credit_atm_locked = payload.value;
            }
        }
    },

})

export const {
    setCredentials,
    unsetCredentials,
    setToken,
    updateUserCredit,
    updateUserCreditLock
} = authSlice.actions

export default authSlice.reducer

export const selectCurrentUser = (state) => state.auth.user
