import {numberWithCommas} from "../../utils/utils";
import {faClock} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import CountDown from "./CountDown";
import {useEffect} from "react";
import moment from "moment";
import {STATIC_FILE_SERVER} from "../../config/app.config";
import {useModal} from "react-modal-hook";
import AuctionDetail from "./AuctionDetail";
import default_acc_ava from "../../assets/image/default-acc-ava.png";
import clock from '../../assets/image/Clock.svg';
import './AuctionItem.scss';


const AuctionItem = (props) => {
    const auction = props.auctionData;
    const deadlineToSecond = moment(new Date(new Date(auction.deadline).getTime() - 7 * 60000 * 60)).unix();
    const [showCUModal, hideCUModal] = useModal(() => (
        <AuctionDetail openModal={true} onClose={hideCUModal} item={auction}/>
    ), [auction]);
    const onViewAccount = () => {
        showCUModal(auction);
    }

    return (

        <div className="auction-item">
            <div className="w-100 d-flex">
                <div className="acc-avatar">
                    <img src={auction.Avatar ? `${STATIC_FILE_SERVER}${auction.Avatar}` : default_acc_ava} alt="default product image"/>
                </div>
                <div className="acc-info">
                    <h6 className="acc-name" title={auction.Title}>{auction.Title}</h6>
                    {/*<p className="info">Giá khởi điểm: <span>{numberWithCommas(auction.start_price ?? 0)} VND</span></p>*/}
                    {/*<p className="info">Giá tối đa: <span>{numberWithCommas(auction.max_price ?? 0)} VND</span></p>*/}
                    {/*<p className="info">Bước giá: <span>{numberWithCommas(auction.increase_step ?? 0)} VND</span></p>*/}
                    {auction.loai_acc && (auction.loai_acc === 2 || auction.loai_acc === 3) ?
                        <>
                            <div className="info">SL BP: <span>{auction.Description}</span></div>
                            {auction.ngay_svip ? <div className="info">Ngày SVIP: <span>{auction.ngay_svip || 0}</span></div>: null}
                            {auction.the_mau ? <div className="info">Thẻ CT: <span>{auction.the_mau || 0}</span></div>: null}
                            {auction.point ? <div className="info">Point: <span>{auction.point || 0}</span></div>: null}
                            {auction.the_bv ? <div className="info">Thẻ BV: <span>{auction.the_bv || 0}</span></div> : null}
                            {auction.the_ncl ? <div className="info">Thẻ NCL: <span>{auction.the_ncl || 0}</span></div> : null}
                            {auction.the_30_percent ? <div className="info">Thẻ 30%: <span>{auction.the_30_percent || 0}</span></div> : null}
                            {auction.the_fc ? <div className="info">FC: <span>{auction.the_fc || 0}</span></div> : null}
                        </>
                        :
                       null
                    }
                    <p className="acc-price mb-0">
                        <span className="label">Giá hiện tại</span>
                        <span className="price">{numberWithCommas(auction.current_price ?? 0)} FGC</span>
                    </p>
                    <button className="btn btn-place-bid mt-2" onClick={onViewAccount}>Đặt giá</button>
                </div>
            </div>
            <div className="auction-deadline">
                <div className="timer">
                    <img src={clock} alt="Clock"/>
                    <CountDown second={deadlineToSecond}/>
                </div>
            </div>
        </div>


        // <div className="auction-account" onClick={onViewAccount}>
        //     <div className="auction-item-info">
        //         <div className="auction-image">
        //             <img src={`${STATIC_FILE_SERVER}${auction.Avatar || auction.Avatar2 || auction.Avatar3}`}/>
        //         </div>
        //         <div className="account-info">
        //             <div className="account-name">{auction.Title}</div>
        //             <div className="account-price">{numberWithCommas(auction.current_price ?? 0)} VND</div>
        //         </div>
        //     </div>
        //     <div className="auction-price">
        //         <div className="bid-info">
        //             <div className="bid-info-label">Giá khởi điểm</div>
        //             <div className="bid-info-value">{numberWithCommas(auction.start_price ?? 0)} VND</div>
        //         </div>
        //         <div className="bid-info">
        //             <div className="bid-info-label">Giá tối đa</div>
        //             <div className="bid-info-value">{numberWithCommas(auction.max_price ?? 0)} VND</div>
        //         </div>
        //         <div className="bid-info">
        //             <div className="bid-info-label">Bước giá</div>
        //             <div className="bid-info-value">{numberWithCommas(auction.increase_step ?? 0)} VND</div>
        //         </div>
        //     </div>
        //     <div className="action">
        //         {auction.user_price ?
        //             <div className="bid-info">
        //                 <div className="bid-info-label">Bạn đã đặt</div>
        //                 <div className="bid-info-value">
        //                     <span className={auction.user_price < auction.current_price ? 'text-danger' : ''}>{numberWithCommas(auction.user_price ?? 0)} VND</span>
        //                 </div>
        //             </div>
        //             :
        //             <button className="btn btn-primary">
        //                 <span>Đặt giá</span>
        //             </button>
        //         }
        //
        //     </div>
        //     <div className="auction-deadline">
        //         <div className="timer">
        //             <FontAwesomeIcon icon={faClock}/>
        //             <CountDown second={deadlineToSecond}/>
        //         </div>
        //     </div>
        // </div>
    );
}
export default AuctionItem;